export default function RerouteConnectorLineIn({ isSelected }) {
  return (
    <svg
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 7V-3.57628e-07"
        stroke={isSelected ? "green" : "#00AAC3"}
        stroke-width="4"
      />
      <path
        d="M28 28L8.70711 8.70711C8.25435 8.25435 8 7.64029 8 7V7"
        stroke={isSelected ? "green" : "#00AAC3"}
        stroke-width="4"
      />
    </svg>
  );
}
