import { Toaster } from "react-hot-toast";

export default function Toast() {
  return (
    <Toaster
      toastOptions={{
        // Define default options
        className: "",
        duration: 10000,
        style: {
          background: "#00AAC3",
          color: "#000",
        },

        // Default options for specific types
        success: {
          style: {
            background: "#06BA63",
            color: "#000",
          },
          iconTheme: {
            primary: "#06BA63",
            secondary: "#000",
          },
        },
        error: {
          style: {
            background: "#E13C55",
            color: "#fff",
          },
          iconTheme: {
            primary: "#E13C55",
            secondary: "#fff",
          },
        },
      }}
    />
  );
}
