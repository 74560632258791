import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrain, faArrowUpRight, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faEye } from "@fortawesome/pro-regular-svg-icons";

export default function DelaysDrawer({ delaysDrawerIsOpen, setDelaysDrawerIsOpen }) {
  return (
    <div className="drawer-side z-10">
      <label htmlFor="delays-drawer" className="drawer-overlay"></label>
      <div className="min-h-full w-full md:w-1/2 xl:w-1/3 bg-base-100 p-5 text-base-content prose">
        <h2>Delays</h2>
        <label
          htmlFor="delays-drawer"
          onClick={() => setDelaysDrawerIsOpen(false)}
          className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
        >
          ✕
        </label>

        <div className="card card-compact mb-7 p-3 border border-info bg-[#F2ECF7]">
          <div className="flex gap-10 pb-2 border-b border-info">
            <div className="flex gap-5">
              <div>
                <div>
                  <FontAwesomeIcon icon={faTrain} /> Trip
                </div>
                <div className="text-[22px] text-primary font-bold">
                  121 <FontAwesomeIcon icon={faArrowUpRight} />
                </div>
              </div>
              <div>
                <div className="text-[#808080]">Date</div>
                <div className="text-[22px] font-bold">3/27/24</div>
              </div>
            </div>

            <div>
              <div className="text-[#808080]">Vehicle Formation</div>
              <div className="flex gap-1 items-center">
                <div className="badge badge-md badge-outline">1074</div>-
                <div className="badge badge-md badge-outline">702</div>
              </div>
            </div>
          </div>

          <div className="pt-2">
            <div className="flex">
              <div className="w-1/2 text-[#808080]">Baseline Passenger Count</div>
              <div className="w-1/2 font-bold">200</div>
            </div>
            <div className="flex">
              <div className="w-1/2 text-[#808080]">Required Crew</div>
              <div className="w-1/2 font-bold">3/3</div>
            </div>
            <div className="flex">
              <div className="w-1/2 text-[#808080]">Bikes Allowed</div>
              <div className="w-1/2 font-bold text-success">
                <FontAwesomeIcon icon={faCheck} />
              </div>
            </div>
            <div className="flex">
              <div className="w-1/2 text-[#808080]">Hazardous Cars</div>
              <div className="w-1/2 font-bold">3</div>
            </div>
          </div>
        </div>

        <div className="mb-7 flex justify-between">
          <div className="flex gap-2">
            <div className="badge badge-md badge-error">4</div>
            logged updates
          </div>
          <button className="btn btn-sm btn-ghost text-primary p-2">
            <span className="hidden sm:inline">View Audit Log</span>
            <FontAwesomeIcon icon={faArrowUpRight} />
          </button>
        </div>

        <div className="card card-compact mb-7 p-3 border border-success bg-[#e6f8ef]">
          <div className="pb-2 border-b border-success">
            <div className="form-control">
              <label className="label gap-2 justify-start cursor-pointer">
                <input
                  type="checkbox"
                  defaultChecked
                  className="checkbox checkbox-primary checkbox-xs"
                />
                <span className="label-text">This is a primary occurrence</span>
              </label>
            </div>
          </div>

          <div className="pt-2 flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <div className="badge badge-md badge-error">2</div>
              linked occurrences
            </div>
            <button className="btn btn-sm btn-ghost text-primary p-2">
              <span className="hidden sm:inline">Show Occurrences</span>
              <FontAwesomeIcon icon={faEye} />
            </button>
          </div>
        </div>

        <div className="flex gap-2">
          <button
            onClick={() => {
              setDelaysDrawerIsOpen(false);
            }}
            className="btn btn-primary btn-outline"
          >
            Cancel
          </button>
          <button className="btn btn-primary">Update Delay</button>
        </div>
      </div>
    </div>
  );
}
