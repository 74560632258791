import { apiUrl, createAuthHelpers } from "./api";
import { useMsal } from "@azure/msal-react";
import toast from "react-hot-toast";

export const useFetchFunctions = () => {
  const { instance: msalInstance } = useMsal();
  const { authHeaders } = createAuthHelpers(msalInstance);

  return {
    createOrUpdateUser: async () => {
      try {
        const headers = await authHeaders(true);
        const url = apiUrl + "/users/me";
        const response = await fetch(url, {
          method: "PUT",
          headers,
        });

        if (!response.ok) {
          toast.error("Unable to create or update your user account in TRMS.");
          throw new Error(`Error creating or updating user: ${response.status}`);
        }

        const json = await response.json();
        return json;
      } catch (error) {
        console.error("Error creating or updating user:", error);
        return { error: error.message };
      }
    },
  }
}