import { useState, useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOut,
  faUserCircle,
  // faChevronsLeft,
} from "@fortawesome/pro-regular-svg-icons";
import Logo from "../assets/logo.png";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import AuditLog from "./AuditLog";
import { useFetchFunctions } from "../services/trips";
import { useQueryClient } from "@tanstack/react-query";
import CreateTrip from "./CreateTrip";
import toast from "react-hot-toast";
import { TripContext } from "../utils/Contexts";
import SignOff from "./SignOff";
import ResetTrip from "./ResetTrip";

export default function Navbar() {
  const isAuthenticated = useIsAuthenticated();
  const { instance: msalInstance } = useMsal();

  const { signOffTrip } = useFetchFunctions();
  const queryClient = useQueryClient();

  const { currentTripContext, setCurrentTripContext } = useContext(TripContext);

  const { operator_short_name, service_date, trip_name } = useParams();
  const [addTripDrawerIsOpen, setAddTripDrawerIsOpen] = useState(false);
  const [auditLogDrawerIsOpen, setAuditLogDrawerIsOpen] = useState(false);
  const [signOffModalIsOpen, setSignOffModalIsOpen] = useState(false);
  const [signingOffTrip, setSigningOffTrip] = useState(false);

  const location = useLocation();

  const showAuditHistory = location.pathname.startsWith("/trips/");
  const showAddTrip = location.pathname === "/trip-search";

  const handleSignOffTrip = async () => {
    setSigningOffTrip(true);

    let payload = {
      signed_off: "True",
    };

    if (currentTripContext.signed_off) {
      payload.signed_off = "False";
    }

    try {
      await signOffTrip(operator_short_name, service_date, trip_name, payload).then(
        (res) => {
          if (!res.error) {
            setSignOffModalIsOpen(false);
            queryClient.setQueryData(
              ["trip", operator_short_name, service_date, trip_name],
              res
            );
            setCurrentTripContext(res);
            toast.success(`Trip ${res?.trip_name} saved`);
          }
          setSigningOffTrip(false);
        }
      );
    } catch (error) {
      console.log("Error:", error);
      setSigningOffTrip(false);
    }
  };

  return (
    <div className="navbar px-4 bg-white sm:bg-info">
      <div className="flex-1 gap-4 hidden sm:flex">
        {/* <Link
          to="/"
          className="btn btn-square btn-outline border-base-100 text-base-100"
        >
          <FontAwesomeIcon icon={faChevronsLeft} />
        </Link> */}

        <div className="drawer drawer-end w-auto">
          <input
            id="add-trip-drawer"
            type="checkbox"
            className="drawer-toggle"
            checked={addTripDrawerIsOpen}
          />
          <input
            id="audit-log-drawer"
            type="checkbox"
            className="drawer-toggle"
            checked={auditLogDrawerIsOpen}
          />
          <div className="drawer-content">
            {showAddTrip && (
              <button
                onClick={() => setAddTripDrawerIsOpen(true)}
                className="btn btn-primary"
              >
                Add Trip
              </button>
            )}
            {showAuditHistory && (
              <button
                className="btn btn-primary"
                onClick={() => setAuditLogDrawerIsOpen(true)}
              >
                Audit History
              </button>
            )}
          </div>
          {showAddTrip && (
            <CreateTrip
              isAuthenticated={isAuthenticated}
              addTripDrawerIsOpen={addTripDrawerIsOpen}
              setAddTripDrawerIsOpen={setAddTripDrawerIsOpen}
            />
          )}
          {showAuditHistory && (
            <AuditLog
              isAuthenticated={isAuthenticated}
              auditLogDrawerIsOpen={auditLogDrawerIsOpen}
              setAuditLogDrawerIsOpen={setAuditLogDrawerIsOpen}
            />
          )}
        </div>

        <SignOff
          tripContext={currentTripContext}
          trip_name={trip_name}
          operator_short_name={operator_short_name}
          service_date={service_date}
          showAuditHistory={showAuditHistory}
          signOffModalIsOpen={signOffModalIsOpen}
          handleSignOffTrip={handleSignOffTrip}
          setSignOffModalIsOpen={setSignOffModalIsOpen}
          signingOffTrip={signingOffTrip}
        />

        <ResetTrip
          trip_name={trip_name}
          operator_short_name={operator_short_name}
          service_date={service_date}
          showAuditHistory={showAuditHistory}
        />
      </div>
      <div className="flex gap-2 justify-between w-full sm:w-auto">
        {isAuthenticated && (
          <div className="flex gap-5 divide-x divide-white/25">
            <div className="hidden sm:flex gap-3 items-center text-primary sm:text-white normal-case avatar">
              {/* <div className="w-10 rounded-full">
              <img
                alt="Tailwind CSS Navbar component"
                src="https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg"
              />
            </div> */}

              <FontAwesomeIcon icon={faUserCircle} size="2x" />
              {msalInstance.getActiveAccount()?.name}
            </div>

            <div>
              <Link to="/" className="btn btn-ghost text-primary sm:text-white">
                <FontAwesomeIcon icon={faSignOut} size="xl" />
              </Link>
            </div>
          </div>
        )}

        <img src={Logo} className="sm:hidden" alt="TRMS" />

        <label htmlFor="side-nav" className="btn btn-ghost drawer-button sm:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="inline-block w-7 h-7 stroke-primary sm:stroke-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </label>
      </div>
    </div>
  );
}
