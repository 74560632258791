import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();
  console.log('error: ', error);

  return (
    // <div id='error-page'>
    //   <h1>Oops!</h1>
    //   <p>Sorry, an unexpected error has occurred.</p>
    //   <p>
    //     <i>{error.statusText || error.message}</i>
    //   </p>
    // </div>
    <main className='grid min-h-full place-items-center bg-base-200 px-6 py-24 sm:py-32 lg:px-8'>
      <div className='text-center'>
        <p className='text-base font-semibold text-secondary-content'>404</p>
        <h1 className='mt-4 text-3xl font-bold tracking-tight text-primary sm:text-5xl'>
          Oops!
        </h1>
        <p className='mt-6 text-base leading-7 text-secondary-content-300'>
          <i>{error.statusText || error.message}</i>
        </p>
        <div className='mt-10 flex items-center justify-center gap-x-6'>
          <a
            href='/'
            className='rounded-md bg-accent px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-accent-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-600'
          >
            Go back home
          </a>
          <a href='#' className='text-sm font-semibold text-info-content'>
            Contact support <span aria-hidden='true'>&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  );
}
