export function ManageColumns({ columns, setVisibleTableColumns }) {
  return (
    <div className="dropdown dropdown-end hidden md:inline-block not-prose">
      <div className="btn btn-sm btn-outline" tabIndex={0}>
        Manage Columns
      </div>
      <ul
        className="manage-columns menu flex-nowrap p-1 shadow dropdown-content z-10 bg-base-100 rounded-box w-52 max-h-80 overflow-y-auto"
        tabIndex={0}
      >
        {columns.map((column, i) => {
          return (
            <li key={i}>
              <div className={`form-control ${column.visible ? "active" : ""} `}>
                <label className="flex justify-start gap-2 cursor-pointer">
                  <input
                    onChange={() => {
                      const updatedColumns = columns.map((col) => {
                        return col.name === column.name
                          ? { ...col, visible: !col.visible }
                          : col;
                      });
                      setVisibleTableColumns(updatedColumns);
                    }}
                    type="checkbox"
                    checked={column.visible}
                    className="checkbox checkbox-primary checkbox-xs"
                  />
                  <span className="label-text">{column.display_name}</span>
                </label>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
