export default function TextArea({ value, handleOnChange, disabled, placeholder }) {
  return (
    <textarea
      placeholder={placeholder}
      className="textarea textarea-bordered h-24"
      value={value}
      onChange={(event) => handleOnChange(event)}
      disabled={disabled}
    ></textarea>
  );
}
