import Table from "../components/Table";
import SingleSelect from "../components/SingleSelect";
import MultiSelect from "../components/MultiSelect";
import DateRangePicker from "../components/DateRangePicker";
import TextField from "../components/TextField";
import LoadingSpinner from "../icons/LoadingSpinner";
import YesNoCheckbox from "../components/YesNoCheckbox";
import Checkbox from "../components/Checkbox";
import { useState } from "react";
import { useFetchFunctions } from "../services/trips";
import "react-datepicker/dist/react-datepicker.css";
import { useQueries } from "@tanstack/react-query";
import { formatInTimeZone, format } from "date-fns-tz";
import { TripSearchPayload } from "../types/trips";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/pro-regular-svg-icons";
import { formatUnderscoreCasing } from "../utils/helperFunctions";
import Pagination from "../components/Pagination";
import ManageOccurrencesTable from "../components/ManageOccurrencesTable";
import DelaysDrawer from "../components/DelaysDrawer";
import UnusualOccurrencesDrawer from "../components/UnusualOccurrencesDrawer";

export async function loader() {
  return {};
}

const TRIP_STATUS_OPTIONS = [
  { value: "NONE", label: "None" },
  { value: "ON_TIME", label: "On Time" },
  { value: "LATE", label: "Late" },
  { value: "CANCELED", label: "Canceled" },
  { value: "TERMINATED", label: "Terminated" },
  { value: "ORIGINATED_ENROUTE", label: "Originated Enroute" },
];

const defaultTripSearchPayload: TripSearchPayload = {
  start_date: formatInTimeZone(new Date(), "America/New_York", "P"),
  end_date: formatInTimeZone(new Date(), "America/New_York", "P"),
  trip_name: "",
  status: "",
  route: "",
  serial_number: "",
  carrier_code: "",
  page: "1",
  page_size: "50",
  sort: "ASC",
  sort_by: "service_date",
};

export default function ManageOccurrences() {
  const [searchPayload, setSearchPayload] = useState<TripSearchPayload>(
    defaultTripSearchPayload
  );
  const [selectedOperator, setSelectedOperator] = useState("");
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [searchResults, setSearchResults] = useState({});
  const [searchResultsTab, setSearchResultsTab] = useState("delays");
  const [delaysDrawerIsOpen, setDelaysDrawerIsOpen] = useState(false);
  const [unusualOccurrencesDrawerIsOpen, setUnusualOccurrencesDrawerIsOpen] =
    useState(false);

  const { fetchOperators, fetchScheduleTypes, fetchOperatorTripFields, fetchTrips } =
    useFetchFunctions();

  const results = useQueries({
    queries: [
      {
        queryKey: ["operators"],
        queryFn: () => fetchOperators(),
        staleTime: 1000 * 10,
      },
      {
        queryKey: ["schedule_types"],
        queryFn: () => fetchScheduleTypes(),
        staleTime: 1000 * 10,
      },
      {
        queryKey: ["operator_trip_fields", selectedOperator?.value],
        queryFn: () => fetchOperatorTripFields(selectedOperator?.value),
        staleTime: 1000 * 10,
        enabled: !!selectedOperator,
      },
    ],
  });

  const operators = results[0];
  const scheduleTypes = results[1];
  const operatorTripFields = results[2];

  const handleChangeDate = (start_date: Date, end_date: Date) => {
    setSearchPayload({
      ...searchPayload,
      start_date,
      end_date,
    });
  };

  const handleNewTripValue = (
    field_name: string,
    newValue: string | boolean | string[] | undefined
  ) => {
    if (newValue === "") {
      newValue = undefined;
    }
    const newTripValues = [
      ...searchPayload.trip_values.filter(
        (trip_value) => trip_value.field_name !== field_name
      ),
      {
        field_name,
        value: newValue,
      },
    ];
    setSearchPayload({
      ...searchPayload,
      trip_values: newTripValues,
    });
  };

  const handleSearch = async (
    operator_short_name: string,
    search_payload: TripSearchPayload
  ) => {
    const formattedTripValues = search_payload.trip_values
      .filter(
        (x) =>
          x.value !== undefined && (!Array.isArray(x.value) || x.value.length !== 0)
      )
      .map((tripValue) => ({
        ...tripValue,
        value: Array.isArray(tripValue.value)
          ? tripValue.value.map((x) => x.value)
          : tripValue.value,
      }));
    const formattedPayload = {
      ...search_payload,
      start_date: format(search_payload.start_date, "yyyy-MM-dd"),
      end_date: format(search_payload.end_date, "yyyy-MM-dd"),
      trip_values: formattedTripValues,
      status: search_payload.status.value,
      schedule_type: search_payload.schedule_type.value,
    };
    setLoadingSearchResults(true);
    try {
      await fetchTrips(operator_short_name, formattedPayload).then((res) => {
        setSearchResults(res);
        setLoadingSearchResults(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingSearchResults(false);
    }
  };

  const handleResetSearch = () => {
    setSelectedOperator(null);
    setSearchPayload(defaultTripSearchPayload);
    setLoadingSearchResults(false);
    setSearchResults({});
  };

  const handleChangePage = (pageNumber, resultsPerPage) => {
    console.log(pageNumber);
    setSearchPayload({
      ...searchPayload,
      page: pageNumber,
      page_size: resultsPerPage,
    });
    handleSearch(selectedOperator.value, {
      ...searchPayload,
      page: pageNumber,
      page_size: resultsPerPage,
    });
  };

  const handleChangeSort = (sort, sort_by) => {
    setSearchPayload({
      ...searchPayload,
      sort: sort,
      sort_by: sort_by,
    });
    handleSearch(selectedOperator.value, {
      ...searchPayload,
      sort: sort,
      sort_by: sort_by,
    });
  };

  return (
    <div>
      <div className="page-header bg-info lg:flex justify-between px-4 py-5">
        <div className="md:flex gap-4 items-center prose text-white">
          <h1 className="flex gap-3 items-center m-0 text-2xl lg:text-4xl text-white">
            Manage Occurrences
          </h1>
        </div>
        <div className="breadcrumbs text-white hidden md:block">
          <ul>
            <li className="text-white/50">Manage Occurrences</li>
          </ul>
        </div>
      </div>

      <div className="drawer drawer-end w-auto">
        <input
          id="delays-drawer"
          type="checkbox"
          className="drawer-toggle"
          checked={delaysDrawerIsOpen}
        />
        <input
          id="unusual-occurrences-drawer"
          type="checkbox"
          className="drawer-toggle"
          checked={unusualOccurrencesDrawerIsOpen}
        />

        {delaysDrawerIsOpen && (
          <DelaysDrawer
            delaysDrawerIsOpen={delaysDrawerIsOpen}
            setDelaysDrawerIsOpen={setDelaysDrawerIsOpen}
          />
        )}
        {unusualOccurrencesDrawerIsOpen && (
          <UnusualOccurrencesDrawer
            unusualOccurrencesDrawerIsOpen={unusualOccurrencesDrawerIsOpen}
            setUnusualOccurrencesDrawerIsOpen={setUnusualOccurrencesDrawerIsOpen}
          />
        )}
      </div>

      <div className="p-5">
        <div className="w-full">
          <div className="card card-compact bg-base-100 border border-base-300 prose">
            <div className="card-body gap-4">
              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Operator</label>
                </div>
                <SingleSelect
                  placeholder={"Select operator..."}
                  value={selectedOperator}
                  isLoading={operators.isLoading}
                  handleOnChange={(o) => setSelectedOperator(o)}
                  options={
                    operators &&
                    Array.isArray(operators?.data) &&
                    operators?.data?.map((operator) => {
                      return { value: operator.short_name, label: operator.name };
                    })
                  }
                />
              </div>
              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Responsible Party</label>
                </div>
                <SingleSelect
                  placeholder={"Select"}
                  value={selectedOperator}
                  isLoading={operators.isLoading}
                  handleOnChange={(o) => setSelectedOperator(o)}
                  options={
                    operators &&
                    Array.isArray(operators?.data) &&
                    operators?.data?.map((operator) => {
                      return { value: operator.short_name, label: operator.name };
                    })
                  }
                />
              </div>
              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Service Date</label>
                </div>
                <DateRangePicker
                  startDate={searchPayload.start_date}
                  endDate={searchPayload.end_date}
                  handleChangeDate={handleChangeDate}
                />
              </div>

              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Occurrence</label>
                </div>
                <div className="flex gap-3 flex-wrap">
                  <SingleSelect
                    placeholder={"Cause"}
                    value={searchPayload.status}
                    handleOnChange={(status) =>
                      setSearchPayload({ ...searchPayload, status: status })
                    }
                    options={TRIP_STATUS_OPTIONS.map((option) => {
                      return { value: option.value, label: option.label };
                    })}
                  />
                  <SingleSelect
                    placeholder={"Waiver Status"}
                    value={searchPayload.schedule_type}
                    isLoading={scheduleTypes.isLoading}
                    handleOnChange={(schedule_type) =>
                      setSearchPayload({ ...searchPayload, schedule_type })
                    }
                    options={
                      scheduleTypes &&
                      Array.isArray(scheduleTypes?.data) &&
                      scheduleTypes?.data?.map((schedule) => {
                        return { value: schedule.id, label: schedule.abbreviation };
                      })
                    }
                  />
                  Primary
                  <Checkbox />
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Trip</label>
                </div>
                <div className="flex gap-3 flex-wrap">
                  <TextField
                    className="w-20"
                    placeholder={"Trip"}
                    value={searchPayload.trip_name}
                    handleOnChange={(event) =>
                      setSearchPayload({
                        ...searchPayload,
                        trip_name: event.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Assets</label>
                </div>
                <SingleSelect
                  placeholder={"Linear Asset"}
                  value={searchPayload.schedule_type}
                  isLoading={scheduleTypes.isLoading}
                  handleOnChange={(schedule_type) =>
                    setSearchPayload({ ...searchPayload, schedule_type })
                  }
                  options={
                    scheduleTypes &&
                    Array.isArray(scheduleTypes?.data) &&
                    scheduleTypes?.data?.map((schedule) => {
                      return { value: schedule.id, label: schedule.abbreviation };
                    })
                  }
                />
                <div className="join">
                  <SingleSelect
                    placeholder={"Stationary Asset Type"}
                    value={searchPayload.schedule_type}
                    isLoading={scheduleTypes.isLoading}
                    handleOnChange={(schedule_type) =>
                      setSearchPayload({ ...searchPayload, schedule_type })
                    }
                    options={
                      scheduleTypes &&
                      Array.isArray(scheduleTypes?.data) &&
                      scheduleTypes?.data?.map((schedule) => {
                        return { value: schedule.id, label: schedule.abbreviation };
                      })
                    }
                  />
                  <SingleSelect
                    placeholder={"Stationary Asset"}
                    value={searchPayload.schedule_type}
                    isLoading={scheduleTypes.isLoading}
                    handleOnChange={(schedule_type) =>
                      setSearchPayload({ ...searchPayload, schedule_type })
                    }
                    options={
                      scheduleTypes &&
                      Array.isArray(scheduleTypes?.data) &&
                      scheduleTypes?.data?.map((schedule) => {
                        return { value: schedule.id, label: schedule.abbreviation };
                      })
                    }
                  />
                </div>
                <div className="join">
                  <TextField
                    className="join-item w-full"
                    placeholder={"Carrier Code"}
                    value={searchPayload.carrier_code}
                    handleOnChange={(event) =>
                      setSearchPayload({
                        ...searchPayload,
                        carrier_code: event.target.value,
                      })
                    }
                  />
                  <TextField
                    className="join-item w-full"
                    placeholder={"Serial #"}
                    value={searchPayload.serial_number}
                    handleOnChange={(event) =>
                      setSearchPayload({
                        ...searchPayload,
                        serial_number: event.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Location</label>
                </div>
                <SingleSelect
                  placeholder={"Select"}
                  value={searchPayload.schedule_type}
                  isLoading={scheduleTypes.isLoading}
                  handleOnChange={(schedule_type) =>
                    setSearchPayload({ ...searchPayload, schedule_type })
                  }
                  options={
                    scheduleTypes &&
                    Array.isArray(scheduleTypes?.data) &&
                    scheduleTypes?.data?.map((schedule) => {
                      return { value: schedule.id, label: schedule.abbreviation };
                    })
                  }
                />
              </div>
            </div>

            <div className="divider m-0 h-px"></div>

            <div className="collapse collapse-arrow">
              <input type="checkbox" />
              <h3 className="collapse-title px-0 m-0">Advanced Filters</h3>
              <div className="collapse-content">
                MMBF N/A <Checkbox />
                Arbitration Review <Checkbox />
                Division <MultiSelect />
              </div>
            </div>

            <div className="divider m-0 h-px"></div>

            <div className="card-footer px-4 py-2">
              <div className="flex gap-3 items-center">
                <button
                  className="btn btn-ghost text-error flex-grow sm:flex-grow-0"
                  disabled={!selectedOperator}
                  onClick={() => handleResetSearch()}
                >
                  <FontAwesomeIcon icon={faRedo} />
                  Reset
                </button>
                <button
                  className="btn btn-primary flex-grow sm:flex-grow-0"
                  disabled={!selectedOperator}
                  onClick={() => handleSearch(selectedOperator.value, searchPayload)}
                >
                  Search
                  {loadingSearchResults && <LoadingSpinner />}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mt-4">
          <div className="card card-compact bg-base-100 border border-base-300 prose">
            <ManageOccurrencesTable
              data={searchResults.results}
              operator_trip_fields={operatorTripFields.data}
              selectedOperator={selectedOperator}
              sort={searchPayload.sort}
              sort_by={searchPayload.sort_by}
              handleChangeSort={handleChangeSort}
              searchResultsTab={searchResultsTab}
              setSearchResultsTab={setSearchResultsTab}
              setDelaysDrawerIsOpen={setDelaysDrawerIsOpen}
              setUnusualOccurrencesDrawerIsOpen={setUnusualOccurrencesDrawerIsOpen}
            />
            {searchResults?.results?.length > 0 && (
              <Pagination
                searchResults={searchResults}
                handleChangePage={handleChangePage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
