export default function NumberField({
  value,
  handleOnChange,
  placeholder,
  className,
  disabled,
}) {
  return (
    <input
      disabled={disabled}
      type="number"
      className={`input input-bordered input-sm lg:input-md ${className ?? ""}`}
      placeholder={placeholder}
      value={value}
      onChange={(event) => handleOnChange(event)}
    />
  );
}
