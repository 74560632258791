export default function Checkbox({
  handleOnChange,
  value,
  toggle,
  defaultChecked,
  disabled,
  checked,
}) {
  return (
    <input
      checked={checked}
      disabled={disabled}
      defaultChecked={defaultChecked}
      type="checkbox"
      value={value}
      className={
        toggle
          ? "toggle toggle-primary toggle-sm lg:toggle-md rounded-full"
          : "checkbox checkbox-primary checkbox-xs"
      }
      onChange={(event) => handleOnChange(event)}
    />
  );
}
