export default function StopTypeStop() {
  return (
    <svg
      width="16"
      height="48"
      viewBox="0 0 16 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="8" y1="48" x2="8" stroke="#06BA63" stroke-width="2" />
      <circle cx="8" cy="24" r="5" fill="#06BA63" />
    </svg>
  );
}
