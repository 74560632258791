export default function RouteStraightVert() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.64645 14.3536C7.84171 14.5488 8.15829 14.5488 8.35355 14.3536L11.5355 11.1716C11.7308 10.9763 11.7308 10.6597 11.5355 10.4645C11.3403 10.2692 11.0237 10.2692 10.8284 10.4645L8 13.2929L5.17157 10.4645C4.97631 10.2692 4.65973 10.2692 4.46447 10.4645C4.2692 10.6597 4.2692 10.9763 4.46447 11.1716L7.64645 14.3536ZM8.5 2C8.5 1.72386 8.27614 1.5 8 1.5C7.72386 1.5 7.5 1.72386 7.5 2H8.5ZM8.5 14V2H7.5L7.5 14H8.5Z"
        fill="black"
      />
    </svg>
  );
}
