import { useState } from "react";
import { useFetchFunctions } from "../../../services/trips";
import { useQueryClient } from "@tanstack/react-query";
import { ManageColumns } from "../../../utils/tableFunctions";
import toast from "react-hot-toast";
import { COLUMNS } from "./constants";
import TripOccurrenceFlyout from "./TripOccurrenceFlyout";
import TableRow from "./TableRow";
import TableRowMobile from "./TableRowMobile";
import DeleteOccurrenceModal from "./DeleteOccurrenceModal";

export default function Occurrences({
  trip,
  service_date,
  operator_short_name,
  occurrenceDrawer,
  setOccurrenceDrawer,
}) {
  //trip occurrence states
  const [occurrenceToBeDeleted, setOccurrenceToBeDeleted] = useState(null);
  const [occurrenceToBeEditedProp, setOccurrenceToBeEditedProp] = useState(null);

  //loading states
  const [deletingOccurrence, setDeletingOccurrence] = useState(false);

  //other visual states
  const [visibleTableColumns, setVisibleTableColumns] = useState(COLUMNS);

  const { deleteOccurrence } = useFetchFunctions();

  const queryClient = useQueryClient();

  const handleDeleteOccurrence = async (
    operator_short_name,
    occurrence_id,
    trip_occurrence_id
  ) => {
    setDeletingOccurrence(true);
    try {
      await deleteOccurrence(
        operator_short_name,
        occurrence_id,
        trip_occurrence_id
      ).then((res) => {
        if (!res.error) {
          setDeletingOccurrence(false);
          setOccurrenceToBeDeleted(null);
          toast.success(`Trip ${trip?.trip_name} saved`);
          const removeIndex = trip.trip_occurrences.findIndex(
            (trip_occurrence) => trip_occurrence.id === trip_occurrence_id
          );
          if (removeIndex !== -1) {
            trip.trip_occurrences.splice(removeIndex, 1);
            queryClient.setQueryData(
              ["trip", operator_short_name, service_date, trip.trip_name],
              (oldData) => {
                return {
                  ...oldData,
                  trip_occurrences: [...trip.trip_occurrences],
                };
              }
            );
          }
        }
      });
    } catch (error) {
      console.log(error);
      setDeletingOccurrence(false);
    }
  };

  const handleClickEditOccurrence = (dataToBeEdited) => {
    const dataToBeEditedJson = JSON.parse(JSON.stringify(dataToBeEdited));
    setOccurrenceToBeEditedProp(dataToBeEditedJson);
  };

  return (
    <div className="drawer drawer-end">
      <input
        id="add-occurrence-drawer"
        type="checkbox"
        className="drawer-toggle"
        checked={occurrenceDrawer}
      />
      <div className="drawer-content">
        <div className="card card-compact bg-base-100 border border-base-300 prose">
          <div className="card-header py-2 px-4 border-b">
            <div className="flex justify-between">
              <h3 className="m-0">Occurrences</h3>
              <div className="flex gap-2 items-center">
                <button
                  onClick={() => setOccurrenceDrawer(true)}
                  className="btn btn-sm btn-primary"
                >
                  Add Occurrence
                </button>
                <ManageColumns
                  columns={visibleTableColumns}
                  setVisibleTableColumns={setVisibleTableColumns}
                />
              </div>
            </div>
          </div>

          <div className="md:hidden">
            {trip?.trip_occurrences?.length < 1 && (
              <div className="px-4 py-2 italic">No occurrences added</div>
            )}
            {trip?.trip_occurrences?.map((trip_occurrence, index) => {
              return (
                <TableRowMobile
                  trip_occurrence={trip_occurrence}
                  key={index}
                  index={index}
                />
              );
            })}
          </div>

          {occurrenceToBeDeleted && (
            <DeleteOccurrenceModal
              trip_occurrence={occurrenceToBeDeleted}
              deletingOccurrence={deletingOccurrence}
              setOccurrenceToBeDeleted={setOccurrenceToBeDeleted}
              handleDeleteOccurrence={handleDeleteOccurrence}
              trip={trip}
              service_date={service_date}
              operator_short_name={operator_short_name}
            />
          )}

          <div className="hidden md:block overflow-x-auto">
            <table className="table m-0">
              <thead>
                <tr>
                  {visibleTableColumns.map((column, id) => {
                    if (column.visible) {
                      return <th key={id}>{column.display_name}</th>;
                    }
                  })}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {trip?.trip_occurrences?.length < 1 && (
                  <tr>
                    <td colSpan={3} className="italic">
                      No occurrences added
                    </td>
                  </tr>
                )}
                {trip?.trip_occurrences?.map((trip_occurrence, index) => {
                  return (
                    <TableRow
                      trip_occurrence={trip_occurrence}
                      key={index}
                      index={index}
                      visibleTableColumns={visibleTableColumns}
                      setOccurrenceToBeDeleted={setOccurrenceToBeDeleted}
                      handleClickEditOccurrence={handleClickEditOccurrence}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <TripOccurrenceFlyout
        operator_short_name={operator_short_name}
        service_date={service_date}
        trip={trip}
        occurrenceDrawer={occurrenceDrawer}
        setOccurrenceDrawer={setOccurrenceDrawer}
        occurrenceToBeEditedProp={occurrenceToBeEditedProp}
        setOccurrenceToBeEditedProp={setOccurrenceToBeEditedProp}
      />
    </div>
  );
}
