import { useState, useContext } from "react";
import { useQueryClient } from "@tanstack/react-query";
import ModalWrapper from "./ModalWrapper";
import LoadingSpinner from "../icons/LoadingSpinner";
import { TripContext } from "../utils/Contexts";
import useFetchFunctions from "../services/trips";
import toast from "react-hot-toast";

function ResetTripModal({
  setResetTripModalIsOpen,
  handleResetTrip,
  resettingTrip,
  operator_short_name,
  service_date,
  trip_name,
}) {
  return (
    <>
      <h3 className="mt-0">Reset Trip</h3>

      <p>
        Are you sure you want to reset
        <span className="font-bold"> Trip {trip_name}</span>? This will return it to
        its original state before any modifications were made.
      </p>

      <div className="modal-action justify-start">
        <button
          className="btn btn-primary btn-outline"
          onClick={() => setResetTripModalIsOpen(false)}
        >
          Cancel
        </button>
        <button
          disabled={resettingTrip}
          className="btn btn-error"
          onClick={() => handleResetTrip()}
        >
          Reset
          {resettingTrip && <LoadingSpinner />}
        </button>
      </div>
    </>
  );
}

export default function ResetTrip({
  trip_name,
  operator_short_name,
  service_date,
  showAuditHistory,
}) {
  const queryClient = useQueryClient();
  const { currentTripContext, setCurrentTripContext } = useContext(TripContext);
  const [resetTripModalIsOpen, setResetTripModalIsOpen] = useState(false);
  const [resettingTrip, setResettingTrip] = useState(false);

  const { resetTrip } = useFetchFunctions();

  const handleResetTrip = async () => {
    setResettingTrip(true);

    try {
      await resetTrip(operator_short_name, service_date, trip_name).then((res) => {
        if (!res.error) {
          setResetTripModalIsOpen(false);
          queryClient.setQueryData(
            ["trip", operator_short_name, service_date, trip_name],
            res
          );
          setCurrentTripContext(res);
          toast.success(`Trip ${res?.trip_name} saved`);
        }
        setResettingTrip(false);
      });
    } catch (error) {
      console.log("Error:", error);
      setResettingTrip(false);
    }
  };

  const ResetButton = () => {
    const disabledStatus =
      !currentTripContext?.isLoading &&
      currentTripContext?.trip_locations.some(
        (location) =>
          location.scheduled_location === false || !location.scheduled_location
      );

    if (currentTripContext?.isLoading) {
      return (
        <button className="btn btn-error" disabled>
          <LoadingSpinner />
        </button>
      );
    }
    return (
      <button
        disabled={!disabledStatus}
        className="btn btn-error"
        onClick={() => setResetTripModalIsOpen(true)}
      >
        Reset Trip
      </button>
    );
  };

  return (
    <>
      {showAuditHistory && <ResetButton />}
      {resetTripModalIsOpen && (
        <ModalWrapper modalIsOpen={resetTripModalIsOpen}>
          <ResetTripModal
            trip_name={trip_name}
            handleResetTrip={handleResetTrip}
            setResetTripModalIsOpen={setResetTripModalIsOpen}
            resettingTrip={resettingTrip}
            operator_short_name={operator_short_name}
            service_date={service_date}
          />
        </ModalWrapper>
      )}
    </>
  );
}
