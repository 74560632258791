import TextField from "../../TextField";
import Checkbox from "../../Checkbox";
import DateTimePicker from "../../DateTimePicker";
import NumberField from "../../NumberField";

const TextFieldComponent = ({ field, payload, setPayload }) => (
  <div className="form-control">
    <label className="label">
      <span className="label-text">{field.label}</span>
    </label>
    <TextField
      className={"flex items-center gap-2"}
      value={payload[field.name]}
      placeholder={field.label}
      handleOnChange={(event) =>
        setPayload({
          ...payload,
          [field.name]: event.target.value,
        })
      }
    />
  </div>
);

const CheckboxComponent = ({ field, payload, setPayload }) => (
  <div className="form-control">
    <label className="label gap-2 justify-start cursor-pointer">
      <Checkbox
        value={payload[field.name]}
        handleOnChange={(event) =>
          setPayload({
            ...payload,
            [field.name]: event.target.checked,
          })
        }
      />
      <span className="label-text"> {field.label}</span>
    </label>
  </div>
);

const DateTimePickerComponent = ({ field, payload, setPayload }) => (
  <div className="form-control">
    <span className="label-text">{field.label} </span>
    <DateTimePicker
      value={payload[field.name]}
      handleOnChange={(date) =>
        setPayload({
          ...payload,
          [field.name]: date,
        })
      }
      className="input input-bordered input-sm lg:input-md w-full"
    />
  </div>
);

const NumberFieldComponent = ({ field, payload, setPayload }) => (
  <div className="form-control">
    <label className="label">
      <span className="label-text">{field.label}</span>
    </label>
    <NumberField
      value={payload[field.name]}
      handleOnChange={(event) =>
        setPayload({
          ...payload,
          [field.name]: event.target.value,
        })
      }
    />
  </div>
);

const FormField = ({ field, payload, setPayload }) => {
  switch (field.type) {
    case "TEXT":
      return (
        <TextFieldComponent
          field={field}
          payload={payload}
          setPayload={setPayload}
        />
      );
    case "BOOLEAN":
      return (
        <CheckboxComponent field={field} payload={payload} setPayload={setPayload} />
      );
    case "DATETIME":
      return (
        <DateTimePickerComponent
          field={field}
          payload={payload}
          setPayload={setPayload}
        />
      );
    case "INTEGER":
      return (
        <NumberFieldComponent
          field={field}
          payload={payload}
          setPayload={setPayload}
        />
      );
    default:
      return null;
  }
};

export default function DynamicFormFields({ fields, payload, setPayload }) {
  return (
    <div>
      {Array.isArray(fields) &&
        fields.map((field) => (
          <FormField
            key={field.name}
            field={field}
            payload={payload}
            setPayload={setPayload}
          />
        ))}
    </div>
  );
}
