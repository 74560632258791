import { formatUnderscoreCasing } from "../../../utils/helperFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { statusColors } from "./constants";

export const FormatGroupLabel = (data) => {
  return (
    <div className="flex justify-between">
      <div>{data.label}</div>
      <div>{data.options.length}</div>
    </div>
  );
};

export const WaiverStatus = ({ waiver_status }) => {
  const statusColor = statusColors[waiver_status];

  if (statusColor !== "") {
    return (
      <div className={`badge badge-sm badge-${statusColor}`}>
        {formatUnderscoreCasing(waiver_status)}
      </div>
    );
  } else {
    return <div className={`badge badge-sm badge-${statusColor}`}></div>;
  }
};

export const PrimaryStatus = ({ is_primary }) => {
  return (
    <FontAwesomeIcon
      icon={is_primary ? faCheck : faTimes}
      className={is_primary ? "text-primary" : "text-error"}
    />
  );
};
