export default function RerouteConnectorLineOut({ isSelected }) {
  return (
    <svg
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 25V32"
        stroke={isSelected ? "green" : "#00AAC3"}
        stroke-width="4"
      />
      <path
        d="M28 4L8.70711 23.2929C8.25435 23.7456 8 24.3597 8 25V25"
        stroke={isSelected ? "green" : "#00AAC3"}
        stroke-width="4"
      />
    </svg>
  );
}
