import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import "./index.css";
import Root from "./routes/root";
import ErrorPage from "./error-page";
import Trips, { loader as tripsLoader } from "./routes/trips.tsx";
import TripSearch, { loader as tripSearchLoader } from "./routes/trip-search.tsx";
import TripDetail, { loader as tripDetailLoader } from "./routes/trip-detail.tsx";
import ManageOccurrences, {
  loader as manageOccurrencesLoader,
} from "./routes/manage-occurrences.tsx";
import SignInRedirect from "./routes/sign-in-redirect.tsx";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { QueryClient } from "@tanstack/react-query";

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
  }

  // Listen for sign-in event and set active account
  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 3600 * 24 * 2, // 2 days
      staleTime: 1000 * 3600, // 1 hour
    },
  },
});

export const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "trips",
        element: <Trips />,
        loader: tripsLoader,
      },
      {
        path: "trip-search",
        element: <TripSearch />,
        loader: tripSearchLoader,
      },
      {
        path: "manage-occurrences",
        element: <ManageOccurrences />,
        loader: manageOccurrencesLoader,
      },
      {
        path: "trip-detail",
        element: <TripDetail />,
        loader: tripDetailLoader,
      },
      {
        path: "trips/operator/:operator_short_name/operator_service_day/:service_date/trip/:trip_name",
        element: <TripDetail />,
        loader: tripDetailLoader,
      },
      {
        path: "sign-in-redirect",
        element: <SignInRedirect />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
      >
        <RouterProvider router={router} />
      </PersistQueryClientProvider>
    </MsalProvider>
  </React.StrictMode>
);
