import Select, {
  type DropdownIndicatorProps,
  ClearIndicatorProps,
  components,
} from "react-select";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/pro-regular-svg-icons";

const SearchIcon = () => {
  return <FontAwesomeIcon icon={faSearch} />;
};

const ClearIcon = () => {
  return <FontAwesomeIcon icon={faTimes} />;
};

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

const ClearIndicator: React.FC<ClearIndicatorProps> = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <ClearIcon />
    </components.ClearIndicator>
  );
};

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    minHeight: "2.5rem",
    fontSize: "0.875rem",
    ":hover": {},
    ":focus": {
      borderColor: "var(--fallback-bc, oklch(var(--bc) / 0.2))",
      outlineStyle: "solid !important",
      outlineWidth: "2px !important",
      outlineOffset: "2px !important",
      outlineColor: "#00AAC3 !important",
      boxShadow: "none",
    },
    ":focus-within": {
      borderColor: "var(--fallback-bc, oklch(var(--bc) / 0.2))",
      outlineStyle: "solid !important",
      outlineWidth: "2px !important",
      outlineOffset: "2px !important",
      outlineColor: "#00AAC3 !important",
      boxShadow: "none",
    },
    "@media only screen and (max-width: 1023px)": {
      ...styles["@media only screen and (max-width: 1023px)"],
      minHeight: "2rem",
      maxHeight: "2rem",
      fontSize: "0.875rem",
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    "@media only screen and (max-width: 1023px)": {
      ...styles["@media only screen and (max-width: 1023px)"],
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  }),
  input: (styles) => ({
    ...styles,
    "@media only screen and (max-width: 1023px)": {
      ...styles["@media only screen and (max-width: 1023px)"],
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: "black",
    "@media only screen and (max-width: 1023px)": {
      ...styles["@media only screen and (max-width: 1023px)"],
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: "black",
    "@media only screen and (max-width: 1023px)": {
      ...styles["@media only screen and (max-width: 1023px)"],
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    width: "0",
  }),
  menuList: (styles) => ({
    ...styles,
    background: "white",
    border: "1px solid #000",
    borderRadius: "4px",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused ? "#E5F6F9" : isSelected ? "#E5F6F9" : undefined,
    color: isFocused ? "black" : isSelected ? "black" : undefined,
    zIndex: 1,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 100,
    boxShadow: "none",
  }),
};

export default function CopyExistingTypeahead({
  title,
  exampleText,
  placeHolder,
  options,
  value,
  handleOnChange,
  buttonText,
  disabled,
}) {
  const [isCopying, setIsCopying] = useState(false);
  return (
    <div className="my-5">
      {!isCopying && (
        <button
          onClick={() => setIsCopying(true)}
          className="btn btn-primary btn-outline"
          disabled={disabled}
        >
          {buttonText}
        </button>
      )}

      {isCopying && (
        <div className="form-control">
          <label className="label">
            <span className="label-text">{title}</span>
          </label>
          <Select
            isDisabled={disabled}
            styles={colourStyles}
            components={{ DropdownIndicator, ClearIndicator }}
            placeholder={placeHolder}
            options={options?.map((option) => {
              return { value: option.value, label: option.label };
            })}
            value={value || ""}
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            onChange={(event) => handleOnChange(event)}
          />
          <label className="label">
            <span className="label-text-alt">{exampleText}</span>
          </label>
          <div className="flex gap-2 justify-end">
            <button
              onClick={() => {
                handleOnChange("");
                setIsCopying(false);
              }}
              className="btn btn-sm btn-ghost text-primary"
            >
              Cancel
            </button>
            <button
              onClick={() => setIsCopying(false)}
              className="btn btn-sm btn-primary"
            >
              Copy
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
