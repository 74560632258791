import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/pro-regular-svg-icons";

import { PrimaryStatus, WaiverStatus } from "./utils";

export default function TableRow({
  trip_occurrence,
  index,
  setOccurrenceToBeDeleted,
  handleClickEditOccurrence,
  visibleTableColumns,
}) {
  const {
    delay_minutes,
    primary,
    operator_trip_occurrence_cause,
    comments,
    waiver_status,
    occurrence,
  } = trip_occurrence;

  const location = occurrence?.location || "N/A";
  const responsible_party = occurrence?.responsible_party || "N/A";

  const columnIsVisible = (columnName) => {
    return visibleTableColumns.some(
      (col) => col.name === columnName && col.visible === true
    );
  };

  return (
    <tr>
      {columnIsVisible("location") && <td>{location}</td>}
      {columnIsVisible("delay_minutes") && <td>{delay_minutes}</td>}
      {columnIsVisible("primary_trip_occurrence") && (
        <td>
          <PrimaryStatus is_primary={primary} />
        </td>
      )}
      {columnIsVisible("type") && <td>{""}</td>}
      {columnIsVisible("cause") && <td>{""}</td>}
      {columnIsVisible("responsible_party") && <td>{responsible_party}</td>}
      {columnIsVisible("comments") && <td>{comments}</td>}
      {columnIsVisible("waiver_status") && (
        <td>
          <WaiverStatus waiver_status={waiver_status} />
        </td>
      )}
      <td>
        <li className="dropdown dropdown-left dropdown-end not-prose">
          <button className="btn btn-sm btn-ghost text-primary" tabIndex={0}>
            <FontAwesomeIcon icon={faEllipsis} size="lg" />
          </button>
          <ul
            tabIndex={0}
            className="menu p-1 shadow dropdown-content z-10 bg-base-100 rounded-box w-32"
          >
            <li>
              <label
                htmlFor="add-occurrence-drawer"
                onClick={() => handleClickEditOccurrence(trip_occurrence)}
              >
                Edit
              </label>
            </li>
            <li>
              <a onClick={() => setOccurrenceToBeDeleted(trip_occurrence)}>Delete</a>
            </li>
          </ul>
        </li>
      </td>
    </tr>
  );
}
