import SingleSelect from "../../SingleSelect";
import { FormatGroupLabel } from "./utils";

const StationaryAssetsField = ({
  stationary_asset,
  occurrencePayload,
  setOccurrencePayload,
  stationaryAssets,
}) => (
  <div className="form-control">
    <label className="label">
      <span className="label-text">Stationary Asset</span>
      {stationary_asset === "REQUIRED" && <span className="text-error">*</span>}
    </label>
    <SingleSelect
      placeholder="Pick one"
      value={occurrencePayload.stationary_asset}
      handleOnChange={(stationary_asset) =>
        setOccurrencePayload({
          ...occurrencePayload,
          stationary_asset,
        })
      }
      formatGroupLabel={FormatGroupLabel}
      options={
        Array.isArray(stationaryAssets.data) &&
        stationaryAssets?.data?.map((stationaryAsset) => {
          const options = stationaryAsset?.assets?.map((asset) => {
            return { value: asset.id, label: asset.name };
          });
          return {
            label: stationaryAsset.location_name,
            options: options,
          };
        })
      }
    />
  </div>
);

export default StationaryAssetsField;
