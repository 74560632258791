import { useState, useEffect, useContext } from "react";
import Checkbox from "./Checkbox";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { useFetchFunctions } from "../services/trips";
import LoadingSpinner from "../icons/LoadingSpinner";
import toast from "react-hot-toast";
import RerouteOrigin from "../icons/RerouteOrigin";
import RerouteServed from "../icons/RerouteServed";
import RerouteUnserved from "../icons/RerouteUnserved";
import RerouteStraightConnector from "../icons/RerouteStraightConnector";
import RerouteDestination from "../icons/RerouteDestination";
import RerouteServedConnectorOut from "../icons/RerouteServedConnectorOut";
import RerouteConnectorLineOut from "../icons/RerouteConnectorLineOut";
import RerouteConnectorLineIn from "../icons/RerouteConnectorLineIn";
import RerouteServedConnectorIn from "../icons/RerouteServedConnectorIn";
import RerouteDestinationConnectorIn from "../icons/RerouteDestinationConnectorIn";
import RerouteOriginConnectorOut from "../icons/RerouteOriginConnectorOut";
import { TripContext } from "../utils/Contexts";

const RerouteDiagramDivs = ({
  existingPath,
  currentReroute,
  index,
  isSelected,
  newRouteStops,
  setNewRouteStops,
  handleSelectAll,
}) => {
  const rerouteOriginSeq = currentReroute.origin_seq;
  const rerouteDestinationSeq = currentReroute.destination_seq;

  const destinationSeqIndex = existingPath.findIndex(
    (x) => x.sequence === rerouteDestinationSeq
  );

  const existingBeforeGray = existingPath.slice(0, rerouteOriginSeq);
  const existingGray = existingPath.slice(rerouteOriginSeq, destinationSeqIndex + 1);
  const existingAfterGray = existingPath.slice(destinationSeqIndex + 1);

  //reroute nodes
  const rerouteLocationsNodes = currentReroute.locations.map((location, i) => {
    if (location.allow_stops) {
      return (
        <div className="flex gap-2 items-center" key={i}>
          <div className="truncate" style={{ maxWidth: "100px" }}>
            {location.name}
          </div>
          <div className="shrink-0" style={{ width: "30px" }}>
            <RerouteServed isSelected={isSelected} />
          </div>
        </div>
      );
    }
    return (
      <div className="flex gap-2 items-center" key={i}>
        <div className="truncate" style={{ maxWidth: "100px" }}>
          {location.name}
        </div>
        <div className="shrink-0" style={{ width: "30px" }}>
          <RerouteUnserved isSelected={isSelected} />
        </div>
      </div>
    );
  });
  //

  //reroute connecting nodes?
  const rerouteConnectingNodes = Array.from({
    length: (existingGray.length - currentReroute.locations.length) / 2 - 1,
  }).map((empty_row, i) => {
    return (
      <div className="flex gap-2" key={i}>
        <div className="flex shrink-0" style={{ width: "30px" }}>
          <RerouteStraightConnector isSelected={isSelected} />
        </div>
      </div>
    );
  });
  //

  //reroute isSelected mode
  const rerouteLocationsCheckboxes = currentReroute.locations.map((location, i) => {
    return (
      <div className="flex gap-2 items-start" key={i}>
        <div className="truncate" style={{ maxWidth: "100px" }}>
          {location.name}
        </div>
        <div className="shrink-0 flex flex-col" style={{ width: "30px" }}>
          <svg
            width="16"
            height="8"
            viewBox="0 0 16 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 39L8 -1.19209e-06" stroke="green" stroke-width="4" />
          </svg>
          <Checkbox
            disabled={!location.allow_stops}
            checked={newRouteStops[location.value]}
            handleOnChange={(event) => {
              const updatedRerouteStops = { ...newRouteStops };
              const value = event.target.checked;
              updatedRerouteStops[location.value] = value;
              setNewRouteStops(updatedRerouteStops);
            }}
          />
          <svg
            width="16"
            height="8"
            viewBox="0 0 16 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 39L8 -1.19209e-06" stroke="green" stroke-width="4" />
          </svg>
        </div>
      </div>
    );
  });
  //

  //existing nodes
  const existingNodesBeforeGray = existingBeforeGray.map((location, i) => {
    if (i === 0 && i === existingBeforeGray.length - 1) {
      return (
        <div className="flex gap-2 items-center" key={i}>
          <div className="flex justify-end shrink-0" style={{ width: "30px" }}>
            <RerouteOriginConnectorOut isSelected={isSelected} />
          </div>
          <div className="truncate" style={{ maxWidth: "100px" }}>
            {location.name}
          </div>
        </div>
      );
    }
    if (i === 0) {
      return (
        <div className="flex gap-2 items-center" key={i}>
          <div className="flex justify-end shrink-0" style={{ width: "30px" }}>
            <RerouteOrigin isSelected={isSelected} />
          </div>
          <div className="truncate" style={{ maxWidth: "100px" }}>
            {location.name}
          </div>
        </div>
      );
    }
    if (i === existingBeforeGray.length - 1) {
      return (
        <div className="flex gap-2" key={i}>
          <div className="flex justify-end shrink-0" style={{ width: "30px" }}>
            <RerouteServedConnectorOut isSelected={isSelected} />
          </div>
          <div className="truncate" style={{ maxWidth: "100px" }}>
            {location.name}
          </div>
        </div>
      );
    }
    if (location.allow_stops) {
      return (
        <div className="flex gap-2 items-center" key={i}>
          <div className="flex justify-end shrink-0" style={{ width: "30px" }}>
            <RerouteServed isSelected={isSelected} />
          </div>
          <div className="truncate" style={{ maxWidth: "100px" }}>
            {location.name}
          </div>
        </div>
      );
    }
    return (
      <div className="flex gap-2 items-center" key={i}>
        <div className="flex justify-end shrink-0" style={{ width: "30px" }}>
          <RerouteUnserved isSelected={isSelected} />
        </div>
        <div className="truncate" style={{ maxWidth: "100px" }}>
          {location.name}
        </div>
      </div>
    );
  });

  const existingNodesGray = existingGray.map((location, i) => {
    if (location.allow_stops) {
      return (
        <div className="flex gap-2 items-center text-[#808080] font-normal" key={i}>
          <div className="flex justify-end shrink-0" style={{ width: "30px" }}>
            <RerouteServed gray />
          </div>
          <div className="truncate" style={{ maxWidth: "100px" }}>
            {location.name}
          </div>
        </div>
      );
    }
    return (
      <div className="flex gap-2 items-center text-[#808080] font-normal" key={i}>
        <div className="flex justify-end shrink-0" style={{ width: "30px" }}>
          <RerouteUnserved gray />
        </div>
        <div className="truncate" style={{ maxWidth: "100px" }}>
          {location.name}
        </div>
      </div>
    );
  });

  const existingGrayConnectingNodes = Array.from({
    length: (currentReroute.locations.length - existingGray.length) / 2 + 1,
  }).map((empty_row, i) => {
    return (
      <div className="flex gap-2" key={i}>
        <div className="flex justify-end shrink-0" style={{ width: "30px" }}>
          <RerouteStraightConnector gray />
        </div>
      </div>
    );
  });

  let finalExistingNodes;

  finalExistingNodes = existingAfterGray.map((location, i) => {
    if (existingAfterGray.length === 0) {
      const lastLocation = existingPath[existingPath.length - 1];
      return (
        <div className="flex gap-2 items-end" key={i}>
          <div className="flex justify-end shrink-0" style={{ width: "30px" }}>
            <RerouteDestination isSelected={isSelected} />
          </div>
          <div className="truncate" style={{ maxWidth: "100px" }}>
            {lastLocation.name}
          </div>
        </div>
      );
    }

    if (i === 0 && i !== existingAfterGray.length - 1) {
      return (
        <div className="flex gap-2 items-end" key={i}>
          <div className="shrink-0" style={{ width: "30px" }}>
            <RerouteServedConnectorIn isSelected={isSelected} />
          </div>
          <div className="truncate" style={{ maxWidth: "100px" }}>
            {location.name}
          </div>
        </div>
      );
    }
    if (i === 0 && i === existingAfterGray.length - 1) {
      return (
        <div className="flex gap-2 items-end" key={i}>
          <div className="flex justify-end shrink-0" style={{ width: "30px" }}>
            <RerouteDestinationConnectorIn isSelected={isSelected} />
          </div>
          <div className="truncate" style={{ maxWidth: "100px" }}>
            {location.name}
          </div>
        </div>
      );
    }
    if (
      i === 0 &&
      i === existingAfterGray.length - 1 &&
      existingAfterGray.length === 1
    ) {
      return (
        <div className="flex gap-2 items-end" key={i}>
          <div className="flex justify-end shrink-0" style={{ width: "30px" }}>
            <RerouteDestinationConnectorIn isSelected={isSelected} />
          </div>
          <div className="truncate" style={{ maxWidth: "100px" }}>
            {location.name}
          </div>
        </div>
      );
    }
    if (i === existingAfterGray.length - 1) {
      return (
        <div className="flex gap-2 items-end" key={i}>
          <div className="flex justify-end shrink-0" style={{ width: "30px" }}>
            <RerouteDestination isSelected={isSelected} />
          </div>
          <div className="truncate" style={{ maxWidth: "100px" }}>
            {location.name}
          </div>
        </div>
      );
    }
    if (location.allow_stops) {
      return (
        <div className="flex gap-2 items-center" key={i}>
          <div className="flex justify-end shrink-0" style={{ width: "30px" }}>
            <RerouteServed isSelected={isSelected} />
          </div>
          <div className="truncate" style={{ maxWidth: "100px" }}>
            {location.name}
          </div>
        </div>
      );
    }
    return (
      <div className="flex gap-2 items-center" key={i}>
        <div className="flex justify-end shrink-0" style={{ width: "30px" }}>
          <RerouteUnserved isSelected={isSelected} />
        </div>
        <div className="truncate" style={{ maxWidth: "100px" }}>
          {location.name}
        </div>
      </div>
    );
  });

  if (existingAfterGray.length === 0) {
    const lastLocation = existingPath[existingPath.length - 1];
    finalExistingNodes = (
      <div className="flex gap-2 items-end">
        <div className="flex justify-end shrink-0" style={{ width: "30px" }}>
          <RerouteDestinationConnectorIn isSelected={isSelected} />
        </div>
        <div className="truncate" style={{ maxWidth: "100px" }}>
          {lastLocation.name}
        </div>
      </div>
    );
  }

  return (
    <div className="w-full pt-5" style={{ fontSize: "14px", fontWeight: 700 }}>
      <div className="mb-5 text-center">
        <button className="btn btn-sm btn-primary">Select Route {index}</button>
      </div>

      <div className="flex">
        <div className="flex-1">
          <div className="flex flex-col items-end text-right"></div>
        </div>
        <div className="flex-1">
          <div className="flex flex-col">{existingNodesBeforeGray}</div>
        </div>
      </div>

      <div className="flex">
        <div className="flex-1" style={{ marginTop: "-7px", marginLeft: "5px" }}>
          <div className="flex flex-col items-end text-right">
            <div className="flex relative">
              {isSelected && rerouteLocationsNodes.length >= 1 && (
                <div className="join absolute right-11 -top-4">
                  <button
                    className="join-item btn btn-sm btn-outline bg-white border-primary text-primary"
                    onClick={() => handleSelectAll(true)}
                  >
                    All
                  </button>
                  <button
                    className="join-item btn btn-sm btn-outline bg-white border-primary text-primary"
                    onClick={() => handleSelectAll(false)}
                  >
                    None
                  </button>
                </div>
              )}
              <RerouteConnectorLineOut isSelected={isSelected} />
            </div>
            {rerouteConnectingNodes}
            {isSelected ? rerouteLocationsCheckboxes : rerouteLocationsNodes}
            {rerouteConnectingNodes}
            <RerouteConnectorLineIn isSelected={isSelected} />
          </div>
        </div>
        <div
          className="flex-1 content-center"
          style={{ marginTop: "-7px", marginLeft: "-5px" }}
        >
          <div className="flex flex-col">
            {existingGrayConnectingNodes}
            {existingNodesGray}
            {existingGrayConnectingNodes}
          </div>
        </div>
      </div>

      <div className="flex">
        <div className="flex-1">
          <div className="flex flex-col items-end text-right"></div>
        </div>
        <div className="flex-1" style={{ marginTop: "-7px" }}>
          <div className="flex flex-col">{finalExistingNodes}</div>
        </div>
      </div>
    </div>
  );
};

export default function RerouteTrip({
  rerouteTripDrawerIsOpen,
  setRerouteTripDrawerIsOpen,
  operator_short_name,
  service_date,
  trip,
}) {
  const [transportationMode, setTransportationMode] = useState(null);
  const [reroutingTrip, setReroutingTrip] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState("");
  const [newRouteStops, setNewRouteStops] = useState({});

  const { fetchAvailableReroutes, postRerouteTrip } = useFetchFunctions();
  const queryClient = useQueryClient();

  const { currentTripContext, setCurrentTripContext } = useContext(TripContext);

  const {
    isLoading,
    isError,
    data: availableReroutes,
    error,
  } = useQuery({
    queryKey: [
      "available_reroutes",
      operator_short_name,
      service_date,
      trip?.trip_name,
    ],
    queryFn: () =>
      fetchAvailableReroutes(operator_short_name, service_date, trip.trip_name),
    staleTime: 1000 * 10,
    enabled: rerouteTripDrawerIsOpen,
  });

  const handleRerouteTrip = async () => {
    const formattedPayload = {};
    formattedPayload.sequence_from = selectedRoute.reroute.origin_seq;
    formattedPayload.sequence_to = selectedRoute.reroute.destination_seq;

    const newRouteStopsJson = JSON.parse(JSON.stringify(newRouteStops));
    const fullRouteStopsJson = JSON.parse(
      JSON.stringify(selectedRoute.reroute.locations)
    );

    formattedPayload.locations = fullRouteStopsJson.map((location) => {
      let isStoppingValue;

      if (!location.allow_stops) {
        isStoppingValue = "False";
      } else {
        isStoppingValue =
          newRouteStopsJson[location.value] === true ? "True" : "False";
      }

      return {
        code: location.value,
        is_stopping: isStoppingValue,
      };
    });

    setReroutingTrip(true);
    try {
      await postRerouteTrip(
        operator_short_name,
        service_date,
        trip.trip_name,
        formattedPayload
      ).then((res) => {
        if (!res.error) {
          setRerouteTripDrawerIsOpen(false);
          queryClient.setQueryData(
            ["trip", operator_short_name, service_date, trip.trip_name],
            res
          );
          setCurrentTripContext(res);
          toast.success(`Trip ${trip?.trip_name} saved`);
        }
        setReroutingTrip(false);
        setNewRouteStops({});
        setSelectedRoute("");
      });
    } catch (error) {
      console.log("Error:", error);
      setReroutingTrip(false);
    }
  };

  useEffect(() => {
    if (selectedRoute?.reroute) {
      const initialStopsToBeEdited = {};
      selectedRoute.reroute.locations.forEach((location) => {
        if (location.allow_stops) {
          initialStopsToBeEdited[location.value] = false;
        }
      });
      setNewRouteStops(initialStopsToBeEdited);
    }
  }, [selectedRoute?.index]);

  const handleSelectAll = (boolean) => {
    const updatedStops = Object.keys(newRouteStops).reduce((acc, key) => {
      acc[key] = boolean;
      return acc;
    }, {});

    setNewRouteStops(updatedStops);
  };

  return (
    <div className="drawer-side z-10">
      <label htmlFor="reroute-trip-drawer" className="drawer-overlay"></label>

      <div className="min-h-full w-full md:w-[500px] bg-base-100 p-5 text-base-content prose">
        {isLoading ? (
          <div className="skeleton h-100 w-20">
            <div className="skeleton min-h-80 w-15"></div>
          </div>
        ) : (
          <>
            <h2>Reroute Trip</h2>
            <label
              htmlFor="reroute-trip-drawer"
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              onClick={() => {
                setRerouteTripDrawerIsOpen(false);
                setSelectedRoute("");
                setNewRouteStops({});
              }}
            >
              ✕
            </label>
            <div className="carousel w-full">
              {availableReroutes?.available_reroutes?.map((reroute, i) => {
                const isSelected = selectedRoute.index === i + 1;

                return (
                  <div
                    key={i}
                    onClick={() => setSelectedRoute({ reroute, index: i + 1 })}
                    id={`item${i + 1}`}
                    className={`carousel-item md:w-10/12 mx-2 pb-4 border rounded-lg ${isSelected ? "bg-success/15 border-success" : "border-primary"}`}
                  >
                    <RerouteDiagramDivs
                      index={i + 1}
                      isSelected={isSelected}
                      existingPath={availableReroutes?.existing_path}
                      currentReroute={reroute}
                      newRouteStops={newRouteStops}
                      setNewRouteStops={setNewRouteStops}
                      handleSelectAll={handleSelectAll}
                    />
                  </div>
                );
              })}
            </div>

            <div className="flex gap-2 mt-5">
              <button
                onClick={() => {
                  setRerouteTripDrawerIsOpen(false);
                  setSelectedRoute("");
                  setNewRouteStops({});
                }}
                className="btn btn-primary btn-outline"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => handleRerouteTrip()}
                disabled={reroutingTrip || !selectedRoute}
              >
                Save
                {reroutingTrip && <LoadingSpinner />}
              </button>
            </div>
          </>
        )}
      </div>

      <div className="w-full md:w-[500px] fixed bottom-10 right-0 p-8">
        <div className="flex w-full justify-center gap-2">
          {availableReroutes?.available_reroutes?.map((reroute, i) => {
            const isSelected = selectedRoute.index === i + 1;

            if (reroute?.locations?.length >= 1) {
              return (
                <a
                  href={`#item${i + 1}`}
                  className={`btn btn-sm btn-outline ${isSelected ? "bg-success" : "bg-white"}`}
                  key={i}
                >
                  {i + 1}
                </a>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}
