export default function StopTypeBypass() {
  return (
    <svg
      width="16"
      height="48"
      viewBox="0 0 16 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 31C4.13401 31 1 27.866 1 24C1 20.134 4.13401 17 8 17"
        stroke="#FFD205"
        stroke-width="2"
      />
      <line x1="8" y1="22" x2="8" stroke="#FFD205" stroke-width="2" />
      <line x1="8" y1="48" x2="8" y2="26" stroke="#FFD205" stroke-width="2" />
      <circle cx="8" cy="24" r="5.5" fill="#FFFAE6" stroke="white" />
    </svg>
  );
}
