import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromArc,
  faArrowRightToArc,
  faMinusCircle,
  faSearch,
  faPlus,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { useState, useEffect, useContext } from "react";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import StopTypeOrigin from "../icons/StopTypeOrigin";
import StopTypeDestination from "../icons/StopTypeDestination";
import StopTypeStop from "../icons/StopTypeStop";
import StopTypeCp from "../icons/StopTypeCp";
import StopTypeBypass from "../icons/StopTypeBypass";
import StopTypeFlag from "../icons/StopTypeFlag";
import RouteStraightVert from "../icons/RouteStraightVert";
import RouteReverseVert from "../icons/RouteReverseVert";
import RouteDoubleReverseVert from "../icons/RouteDoubleReverseVert";
import { formatInTimeZone, format } from "date-fns-tz";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "../icons/LoadingSpinner";
import { ManageColumns } from "../utils/tableFunctions";
import Checkbox from "./Checkbox";
import SingleSelect from "./SingleSelect";
import DateTimePicker from "./DateTimePicker";
import { useFetchFunctions } from "../services/trips";
import NumberField from "./NumberField";
import toast from "react-hot-toast";
import TextField from "./TextField";
import TextArea from "./TextArea";
import ModalWrapper from "./ModalWrapper";
import ModifyTrip from "./ModifyTrip";
import RerouteTrip from "./RerouteTrip";
import { TripContext } from "../utils/Contexts";
import { handlePotentialBooleanStrings } from "../utils/helperFunctions";

const COLUMNS = [
  { name: "location", display_name: "Location", visible: true, can_edit: true },
  {
    name: "actual_is_stopping",
    display_name: "Served",
    visible: true,
    can_edit: true,
  },
  {
    name: "scheduled_arrival",
    display_name: "Scheduled Arrival",
    visible: true,
    can_edit: true,
  },
  {
    name: "scheduled_departure",
    display_name: "Scheduled Departure",
    visible: true,
    can_edit: true,
  },
  { name: "originate", display_name: "Origin", visible: true, can_edit: true },
  { name: "terminate", display_name: "Terminate", visible: true, can_edit: true },
];

const ServedFlag = ({ trip_location }) => {
  if (trip_location.location.allow_stops === false) {
    return <></>;
  } else if (trip_location.actual_is_stopping === true) {
    return <div className="badge badge-sm badge-success">Yes</div>;
  } else {
    return <div className="badge badge-sm badge-error">No</div>;
  }
};

function OriginateModal({
  originateModalIsOpen,
  setOriginateModalIsOpen,
  handleOriginateTripLocation,
  originatingTripLocation,
}) {
  const tripLocationToOriginate = { ...originateModalIsOpen };
  return (
    <>
      <h3 className="mt-0">Originate</h3>

      <p>
        Are you sure you want to originate the trip at{" "}
        <span className="font-bold">{tripLocationToOriginate?.location?.name}</span>?
      </p>

      <div className="modal-action justify-start">
        <button
          className="btn btn-primary btn-outline"
          onClick={() => setOriginateModalIsOpen(false)}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={() => handleOriginateTripLocation(tripLocationToOriginate?.id)}
          disabled={originatingTripLocation}
        >
          Originate
          {originatingTripLocation && <LoadingSpinner />}
        </button>
      </div>
    </>
  );
}

function TerminateModal({
  terminateModalIsOpen,
  setTerminateModalIsOpen,
  handleTerminateTripLocation,
  terminatingTripLocation,
}) {
  const tripLocationToTerminate = { ...terminateModalIsOpen };
  const [terminateExplanation, setTerminateExplanation] = useState("");

  return (
    <>
      <h3 className="mt-0">Originate</h3>

      <p>
        Are you sure you want to terminate the trip at{" "}
        <span className="font-bold">{tripLocationToTerminate?.location?.name}</span>?
      </p>

      <div className="form-control">
        <label className="label">
          <span className="label-text">
            Explain termination <span className="text-error">*</span>
          </span>
        </label>
        <TextArea
          placeholder={"Explanation"}
          value={terminateExplanation}
          handleOnChange={(event) => setTerminateExplanation(event.target.value)}
        />
      </div>

      <div className="modal-action justify-start">
        <button
          className="btn btn-primary btn-error"
          onClick={() => setTerminateModalIsOpen(false)}
        >
          Cancel
        </button>
        <button
          disabled={terminateExplanation === "" || terminatingTripLocation}
          className="btn btn-primary"
          onClick={() =>
            handleTerminateTripLocation(
              tripLocationToTerminate?.id,
              terminateExplanation
            )
          }
        >
          Terminate
          {terminatingTripLocation && <LoadingSpinner />}
        </button>
      </div>
    </>
  );
}

function TableRow({
  trip,
  trip_location,
  index,
  editMode,
  locationsToBeEdited,
  setLocationsToBeEdited,
  operatorTripLocationFields,
  visibleTableColumns,
  setOriginateModalIsOpen,
  setTerminateModalIsOpen,
}) {
  const {
    location,
    actual_is_stopping,
    scheduled_arrival,
    scheduled_departure,
    trip_location_values,
    id,
  } = trip_location;

  const dynamicColumns = [...visibleTableColumns].filter(
    (column) => !COLUMNS.some((staticColumn) => column.name === staticColumn.name)
  );

  const columnIsVisible = (columnName) => {
    return visibleTableColumns.some(
      (col) => col.name === columnName && col.visible === true
    );
  };

  const bubbleDiagramIcon = () => {
    if (index === 0 && !trip_location.actual_location) {
      return <StopTypeOrigin red />;
    }
    if (index === 0) {
      return <StopTypeOrigin />;
    }
    if (index === trip.trip_locations.length - 1 && !trip_location.actual_location) {
      return <StopTypeDestination red />;
    }
    if (index === trip.trip_locations.length - 1) {
      return <StopTypeDestination />;
    }
    if (
      trip_location.actual_location &&
      trip_location.location.allow_stops &&
      trip_location.actual_is_stopping
    ) {
      return <StopTypeStop />;
    }
    if (
      trip_location.actual_location &&
      trip_location.location.allow_stops &&
      !trip_location.actual_is_stopping
    ) {
      return <StopTypeCp />;
    }
    if (trip_location.actual_location && !trip_location.location.allow_stops) {
      return <StopTypeBypass />;
    }
    if (!trip_location.actual_location) {
      return <StopTypeFlag />;
    }
  };

  return (
    <tr className="hover:bg-[#E5F6F9] border-0">
      <td className="pr-0">{bubbleDiagramIcon()}</td>
      {columnIsVisible("location") && <td>{location?.name}</td>}
      {columnIsVisible("actual_is_stopping") && (
        <td>
          {!editMode ? (
            <ServedFlag trip_location={trip_location} />
          ) : (
            <Checkbox
              disabled={!location?.allow_stops || !trip_location?.actual_location}
              handleOnChange={(event) => {
                const updatedActualIsStopping = { ...locationsToBeEdited };
                const value = event.target.checked;
                if (value === actual_is_stopping) {
                  delete updatedActualIsStopping[id].actual_is_stopping;
                  if (Object.keys(updatedActualIsStopping[id]).length === 0) {
                    delete updatedActualIsStopping[id];
                  }
                } else {
                  if (!updatedActualIsStopping[id]) {
                    updatedActualIsStopping[id] = {};
                  }
                  updatedActualIsStopping[id].actual_is_stopping = {
                    value: value,
                  };
                }
                setLocationsToBeEdited(updatedActualIsStopping);
              }}
              defaultChecked={
                actual_is_stopping === true ||
                actual_is_stopping === "True" ||
                actual_is_stopping === "true"
              }
            />
          )}
        </td>
      )}
      {columnIsVisible("scheduled_arrival") && (
        <td>
          {scheduled_arrival &&
            formatInTimeZone(scheduled_arrival, "America/New_York", "h:mm a")}
        </td>
      )}
      {columnIsVisible("scheduled_departure") && (
        <td>
          {scheduled_departure &&
            formatInTimeZone(scheduled_departure, "America/New_York", "h:mm a")}
        </td>
      )}
      {columnIsVisible("originate") && (
        <td className="text-center">
          <FontAwesomeIcon
            onClick={() => setOriginateModalIsOpen(trip_location)}
            icon={faArrowRightFromArc}
            size="xl"
            className="text-primary cursor-pointer"
          />
        </td>
      )}
      {columnIsVisible("terminate") && (
        <td className="text-center">
          <FontAwesomeIcon
            onClick={() => setTerminateModalIsOpen(trip_location)}
            icon={faArrowRightToArc}
            size="xl"
            className="text-error cursor-pointer"
          />
        </td>
      )}

      {!editMode &&
        dynamicColumns
          .filter((column) =>
            visibleTableColumns.some(
              (visibleColumn) =>
                visibleColumn.name === column.name && visibleColumn.visible === true
            )
          )
          .map((column) => {
            const tripLocationValue = trip_location_values.find(
              (value) => value.trip_location_field.name === column.name
            );

            if (
              tripLocationValue?.value &&
              operatorTripLocationFields?.length > 0 &&
              operatorTripLocationFields?.find(
                (field) =>
                  tripLocationValue?.operator_trip_location_field === field?.id &&
                  field?.trip_location_field?.type === "DATETIME"
              )
            ) {
              if (tripLocationValue.value === "None") {
                return <td key={column.name}>None</td>;
              }
              return (
                <td key={column.name}>
                  {format(tripLocationValue?.value, "h:mm a")}
                </td>
              );
            } else if (
              tripLocationValue?.value &&
              operatorTripLocationFields?.length > 0 &&
              operatorTripLocationFields?.find(
                (field) =>
                  tripLocationValue?.operator_trip_location_field === field?.id &&
                  field?.trip_location_field?.type === "BOOLEAN"
              )
            ) {
              return (
                <td>
                  {handlePotentialBooleanStrings(tripLocationValue?.value) ? (
                    <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} className="text-error" />
                  )}
                </td>
              );
            } else if (tripLocationValue?.value) {
              return <td key={column.name}>{tripLocationValue?.value}</td>;
            } else {
              return <td key={column.name}></td>;
            }
          })}
      {editMode &&
        operatorTripLocationFields?.length > 0 &&
        operatorTripLocationFields?.map((operatorTripLocationField) => {
          const hasAtLeastOneValue = trip.trip_locations.some((trip_location) =>
            trip_location.trip_location_values.some(
              (trip_location_value) =>
                trip_location_value.operator_trip_location_field ===
                  operatorTripLocationField.trip_location_field.id &&
                trip_location_value
            )
          );

          if (!hasAtLeastOneValue && !operatorTripLocationField.can_edit) {
            return;
          }

          const tripLocationValue = trip_location_values.find(
            (value) =>
              value?.operator_trip_location_field === operatorTripLocationField?.id
          );

          if (
            operatorTripLocationField?.trip_location_field?.type === "TEXT" &&
            operatorTripLocationField?.lookup_list_items?.length === 0
          ) {
            return (
              <td key={operatorTripLocationField?.trip_location_field.name}>
                <TextField
                  disabled={!operatorTripLocationField?.can_edit}
                  handleOnChange={(event) => {
                    const updatedValues = { ...locationsToBeEdited };
                    if (event.target.value === tripLocationValue?.value) {
                      delete updatedValues[id].values[
                        operatorTripLocationField?.trip_location_field?.name
                      ];
                      if (Object.keys(updatedValues[id].values).length === 0) {
                        delete updatedValues[id];
                      }
                    } else {
                      updatedValues[id] = {
                        ...updatedValues[id],
                        values: {
                          ...updatedValues[id]?.values,
                          [operatorTripLocationField?.trip_location_field?.name]: {
                            id: tripLocationValue?.id,
                            value: event.target.value,
                          },
                        },
                      };
                    }
                    setLocationsToBeEdited(updatedValues);
                  }}
                  value={
                    (locationsToBeEdited[id]?.values &&
                      locationsToBeEdited[id]?.values[
                        operatorTripLocationField?.trip_location_field?.name
                      ]?.value) ??
                    (trip?.trip_locations
                      ?.find((x) => x?.id === id)
                      ?.trip_location_values?.find(
                        (x) =>
                          x?.operator_trip_location_field ===
                          operatorTripLocationField?.trip_location_field?.id
                      ) &&
                      trip?.trip_locations
                        ?.find((x) => x?.id === id)
                        ?.trip_location_values.find(
                          (x) =>
                            x?.operator_trip_location_field ===
                            operatorTripLocationField?.trip_location_field?.id
                        )?.value)
                  }
                />
              </td>
            );
          }

          if (operatorTripLocationField?.trip_location_field?.type === "INTEGER") {
            return (
              <td key={operatorTripLocationField?.trip_location_field?.name}>
                <NumberField
                  disabled={!operatorTripLocationField?.can_edit}
                  handleOnChange={(event) => {
                    const updatedValues = { ...locationsToBeEdited };
                    if (event.target.value === tripLocationValue?.value) {
                      delete updatedValues[id].values[
                        operatorTripLocationField?.trip_location_field?.name
                      ];
                      if (Object.keys(updatedValues[id].values).length === 0) {
                        delete updatedValues[id];
                      }
                    } else {
                      updatedValues[id] = {
                        ...updatedValues[id],
                        values: {
                          ...updatedValues[id]?.values,
                          [operatorTripLocationField?.trip_location_field?.name]: {
                            id: tripLocationValue?.id,
                            value: event.target.value,
                          },
                        },
                      };
                    }
                    setLocationsToBeEdited(updatedValues);
                  }}
                  value={
                    (locationsToBeEdited[id]?.values &&
                      locationsToBeEdited[id]?.values[
                        operatorTripLocationField?.trip_location_field?.name
                      ]?.value) ??
                    (trip?.trip_locations
                      ?.find((x) => x?.id === id)
                      ?.trip_location_values?.find(
                        (x) =>
                          x?.operator_trip_location_field ===
                          operatorTripLocationField?.trip_location_field?.id
                      ) &&
                      trip?.trip_locations
                        ?.find((x) => x?.id === id)
                        ?.trip_location_values.find(
                          (x) =>
                            x?.operator_trip_location_field ===
                            operatorTripLocationField?.trip_location_field?.id
                        )?.value)
                  }
                />
              </td>
            );
          }

          if (operatorTripLocationField?.trip_location_field?.type === "BOOLEAN") {
            return (
              <td key={operatorTripLocationField?.trip_location_field.name}>
                <Checkbox
                  disabled={!operatorTripLocationField?.can_edit}
                  defaultChecked={
                    tripLocationValue?.value === true ||
                    tripLocationValue?.value === "True" ||
                    tripLocationValue?.value === "true"
                  }
                  handleOnChange={(event) => {
                    const value = event.target.checked;
                    const updatedValues = { ...locationsToBeEdited };
                    if (value === tripLocationValue?.value) {
                      delete updatedValues[id].values[
                        operatorTripLocationField?.trip_location_field?.name
                      ];
                      if (Object.keys(updatedValues[id].values).length === 0) {
                        delete updatedValues[id];
                      }
                    } else {
                      updatedValues[id] = {
                        ...updatedValues[id],
                        values: {
                          ...updatedValues[id]?.values,
                          [operatorTripLocationField?.trip_location_field?.name]: {
                            id: tripLocationValue?.id,
                            value: value,
                          },
                        },
                      };
                    }
                    setLocationsToBeEdited(updatedValues);
                  }}
                />
              </td>
            );
          }

          if (
            operatorTripLocationField?.trip_location_field?.type === "TEXT" &&
            operatorTripLocationField?.lookup_list_items?.length > 0
          ) {
            return (
              <td key={operatorTripLocationField?.trip_location_field.name}>
                <SingleSelect
                  isDisabled={!operatorTripLocationField?.can_edit}
                  placeholder={tripLocationValue?.value}
                  options={operatorTripLocationField.lookup_list_items.map(
                    (option) => {
                      return { value: option, label: option };
                    }
                  )}
                  handleOnChange={(option, action) => {
                    const updatedValues = { ...locationsToBeEdited };
                    if (
                      action?.action === "clear" ||
                      option?.value === tripLocationValue?.value
                    ) {
                      delete updatedValues[id].values[
                        operatorTripLocationField?.trip_location_field?.name
                      ];
                      if (Object.keys(updatedValues[id].values).length === 0) {
                        delete updatedValues[id];
                      }
                    } else {
                      updatedValues[id] = {
                        ...updatedValues[id],
                        values: {
                          ...updatedValues[id]?.values,
                          [operatorTripLocationField?.trip_location_field?.name]: {
                            id: tripLocationValue?.id,
                            value: option,
                          },
                        },
                      };
                    }
                    setLocationsToBeEdited(updatedValues);
                  }}
                  value={
                    locationsToBeEdited[id]?.values &&
                    locationsToBeEdited[id]?.values[
                      operatorTripLocationField?.trip_location_field?.name
                    ]?.value
                  }
                />
              </td>
            );
          }

          if (operatorTripLocationField?.trip_location_field?.type === "DATETIME") {
            return (
              <td key={operatorTripLocationField?.trip_location_field.name}>
                <DateTimePicker
                  disabled={!operatorTripLocationField?.can_edit}
                  handleOnChange={(event) => {
                    const updatedValues = { ...locationsToBeEdited };
                    if (event === tripLocationValue?.value || event === "") {
                      delete updatedValues[id].values[
                        operatorTripLocationField?.trip_location_field?.name
                      ];
                      if (Object.keys(updatedValues[id].values).length === 0) {
                        delete updatedValues[id];
                      }
                    } else {
                      updatedValues[id] = {
                        ...updatedValues[id],
                        values: {
                          ...updatedValues[id]?.values,
                          [operatorTripLocationField?.trip_location_field?.name]: {
                            id: tripLocationValue?.id,
                            value: event,
                          },
                        },
                      };
                    }
                    setLocationsToBeEdited(updatedValues);
                  }}
                  value={
                    (locationsToBeEdited[id]?.values &&
                      locationsToBeEdited[id]?.values[
                        operatorTripLocationField?.trip_location_field?.name
                      ]?.value) ??
                    (trip?.trip_locations
                      ?.find((x) => x?.id === id)
                      ?.trip_location_values?.find(
                        (x) =>
                          x?.operator_trip_location_field ===
                          operatorTripLocationField?.id
                      ) &&
                      trip?.trip_locations
                        ?.find((x) => x?.id === id)
                        ?.trip_location_values.find(
                          (x) =>
                            x?.operator_trip_location_field ===
                            operatorTripLocationField?.id
                        )?.value)
                  }
                />
              </td>
            );
          }
        })}
    </tr>
  );
}

const defineDynamicColumns = (
  editMode: boolean,
  trip_locations: [],
  operatorTripLocationFields: [],
  visibleTableColumns: []
) => {
  const dynamicColumns: {
    name: string;
    display_name: string;
    visible: boolean;
  }[] = [];

  if (editMode === true && operatorTripLocationFields.length > 0) {
    for (const operatorTripLocationField of operatorTripLocationFields) {
      const fieldName = operatorTripLocationField?.trip_location_field?.name;
      const hasAtLeastOneValue = trip_locations.some((trip_location) =>
        trip_location.trip_location_values.some(
          (trip_location_value) =>
            trip_location_value.operator_trip_location_field ===
              operatorTripLocationField.trip_location_field.id && trip_location_value
        )
      );

      if (!hasAtLeastOneValue && !operatorTripLocationField?.can_edit) {
        continue;
      }

      if (!dynamicColumns.some((col) => col.name === fieldName) && fieldName) {
        dynamicColumns.push({
          name: fieldName,
          display_name: operatorTripLocationField?.trip_location_field?.label ?? "",
          visible: true,
          can_edit: operatorTripLocationField?.can_edit ?? false,
        });
      }
    }
  } else if (editMode === false) {
    for (const location of trip_locations || []) {
      for (const value of location?.trip_location_values || []) {
        const fieldName = value?.trip_location_field?.name;
        if (
          !dynamicColumns.some((col) => col.name === fieldName) &&
          value?.value !== "" &&
          fieldName
        ) {
          dynamicColumns.push({
            name: fieldName,
            display_name: value?.trip_location_field?.label ?? "",
            visible: true,
          });
        }
      }
    }
  }

  return dynamicColumns;
};

export default function TripLocationsTable({
  isLoading,
  trip,
  operator_short_name,
  service_date,
  operatorTripLocationFields,
}) {
  const [visibleTableColumns, setVisibleTableColumns] = useState(COLUMNS);
  const [showNonStation, setShowNonStation] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [locationsToBeEdited, setLocationsToBeEdited] = useState({});
  const [updatingTripLocations, setUpdatingTripLocations] = useState(false);

  const [originateModalIsOpen, setOriginateModalIsOpen] = useState(false);
  const [originatingTripLocation, setOriginatingTripLocation] = useState(false);

  const [terminateModalIsOpen, setTerminateModalIsOpen] = useState(false);
  const [terminatingTripLocation, setTerminatingTripLocation] = useState(false);

  const [modifyTripDrawerIsOpen, setModifyTripDrawerIsOpen] = useState(false);
  const [rerouteTripDrawerIsOpen, setRerouteTripDrawerIsOpen] = useState(false);

  const { currentTripContext, setCurrentTripContext } = useContext(TripContext);

  const {
    editTripLocations,
    originateTripLocation,
    terminateTripLocation,
    fetchPaths,
  } = useFetchFunctions();

  const {
    isLoading: checkingIfIsEligibleForReroute,
    isError,
    data: possibleRoutes,
    error,
  } = useQuery({
    queryKey: [
      "is_eligible_for_reroute",
      operator_short_name,
      service_date,
      trip?.trip_name,
    ],
    queryFn: () =>
      fetchPaths(
        operator_short_name,
        trip?.trip_locations[0].location?.code,
        trip?.trip_locations[trip?.trip_locations.length - 1].location?.code
      ),
    staleTime: 1000 * 10,
    enabled: !!trip && !isLoading,
  });

  useEffect(() => {
    if (trip && !isLoading) {
      const newColumns = defineDynamicColumns(
        editMode,
        trip?.trip_locations,
        operatorTripLocationFields,
        visibleTableColumns
      );
      setVisibleTableColumns([...COLUMNS, ...newColumns]);
    }
  }, [trip, isLoading, editMode]);

  const handleSaveTripLocationsTable = async () => {
    const locationsToBeEditedJson = JSON.parse(JSON.stringify(locationsToBeEdited));
    const formattedPayload = [];

    for (const locationId in locationsToBeEditedJson) {
      const location = {};
      location.id = parseInt(locationId);

      location.values = [];
      for (const value in locationsToBeEditedJson[locationId]?.values) {
        location.values.push({
          field: value,
          value: locationsToBeEditedJson[locationId]?.values[value]?.value,
        });
      }

      const actualIsStoppingValue =
        locationsToBeEditedJson[locationId]?.actual_is_stopping?.value;

      if (actualIsStoppingValue !== undefined) {
        location.actual_is_stopping = actualIsStoppingValue;
      }

      formattedPayload.push(location);
    }

    setUpdatingTripLocations(true);
    try {
      await editTripLocations(
        operator_short_name,
        service_date,
        trip.trip_name,
        formattedPayload
      ).then((res) => {
        if (!res.error) {
          setEditMode(false);
          trip.trip_locations = res.trip_locations;
          toast.success(`Trip ${trip?.trip_name} saved`);
          setCurrentTripContext(res);
          setLocationsToBeEdited({});
        }
        setUpdatingTripLocations(false);
      });
    } catch (error) {
      console.log("Error:", error);
      setUpdatingTripLocations(false);
    }
  };

  const handleOriginateTripLocation = async (trip_location_id) => {
    const formattedPayload = {
      trip_location_id: parseInt(trip_location_id),
    };

    setOriginatingTripLocation(true);
    try {
      await originateTripLocation(
        operator_short_name,
        service_date,
        trip.trip_name,
        formattedPayload
      ).then((res) => {
        if (!res.error) {
          trip.trip_locations = res.trip_locations;
          toast.success(`Trip ${trip?.trip_name} saved`);
          setCurrentTripContext(res);
        }
        setOriginatingTripLocation(false);
        setOriginateModalIsOpen(false);
      });
    } catch (error) {
      console.log("Error:", error);
      setOriginatingTripLocation(false);
      setOriginateModalIsOpen(false);
    }
  };

  const handleTerminateTripLocation = async (trip_location_id, explanation) => {
    const formattedPayload = {
      trip_location_id: parseInt(trip_location_id),
    };

    setTerminatingTripLocation(true);
    try {
      await terminateTripLocation(
        operator_short_name,
        service_date,
        trip.trip_name,
        formattedPayload
      ).then((res) => {
        if (!res.error) {
          trip.trip_locations = res.trip_locations;
          toast.success(`Trip ${trip?.trip_name} saved`);
          setCurrentTripContext(res);
        }
        setTerminatingTripLocation(false);
        setTerminateModalIsOpen(false);
      });
    } catch (error) {
      console.log("Error:", error);
      setTerminatingTripLocation(false);
      setTerminateModalIsOpen(false);
    }
  };

  return (
    <div className="drawer drawer-end">
      <input
        id="modify-trip-drawer"
        checked={modifyTripDrawerIsOpen}
        type="checkbox"
        className="drawer-toggle"
      />
      <input
        id="reroute-trip-drawer"
        checked={rerouteTripDrawerIsOpen}
        type="checkbox"
        className="drawer-toggle"
      />

      {originateModalIsOpen && (
        <ModalWrapper modalIsOpen={originateModalIsOpen}>
          <OriginateModal
            originateModalIsOpen={originateModalIsOpen}
            setOriginateModalIsOpen={setOriginateModalIsOpen}
            handleOriginateTripLocation={handleOriginateTripLocation}
            originatingTripLocation={originatingTripLocation}
          />
        </ModalWrapper>
      )}

      {terminateModalIsOpen && (
        <ModalWrapper modalIsOpen={terminateModalIsOpen}>
          <TerminateModal
            terminateModalIsOpen={terminateModalIsOpen}
            setTerminateModalIsOpen={setTerminateModalIsOpen}
            terminatingTripLocation={terminatingTripLocation}
            handleTerminateTripLocation={handleTerminateTripLocation}
          />
        </ModalWrapper>
      )}

      <div className="drawer-content">
        <div className="card card-compact bg-base-100 border border-base-300 prose">
          <div className="card-header py-2 px-4 border-b">
            <div className="flex justify-between">
              <h3 className="m-0">Trip Locations</h3>
              <div className="flex gap-2 items-center">
                {!editMode ? (
                  <>
                    <div className="hidden md:block form-control">
                      <label className="label">
                        <Checkbox
                          defaultChecked
                          value={showNonStation}
                          handleOnChange={(event) =>
                            setShowNonStation(event.target.checked)
                          }
                        />
                        <span className="label-text font-bold ml-2">
                          Show non-stations
                        </span>
                      </label>
                    </div>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => setEditMode(true)}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => setModifyTripDrawerIsOpen(true)}
                      className="btn btn-sm btn-primary"
                    >
                      Extend
                    </button>
                    <button
                      className="btn btn-sm btn-primary hidden md:inline-block"
                      onClick={() => setRerouteTripDrawerIsOpen(true)}
                      disabled={
                        checkingIfIsEligibleForReroute || possibleRoutes?.length <= 1
                      }
                    >
                      {checkingIfIsEligibleForReroute ? (
                        <LoadingSpinner />
                      ) : (
                        "Reroute"
                      )}
                    </button>
                    <ManageColumns
                      columns={visibleTableColumns}
                      setVisibleTableColumns={setVisibleTableColumns}
                    />
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => handleSaveTripLocationsTable()}
                      disabled={
                        Object.keys(locationsToBeEdited)?.length === 0 ||
                        updatingTripLocations
                      }
                    >
                      Save
                      {updatingTripLocations && <LoadingSpinner />}
                    </button>
                    <button
                      onClick={() => {
                        setEditMode(false);
                        setLocationsToBeEdited({});
                      }}
                      className="btn btn-sm btn-primary"
                    >
                      Cancel
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="max-h-96 overflow-auto">
            <table className="trips-table table m-0 lg:text-base table-pin-rows">
              <thead>
                <tr>
                  <th className="pr-0"></th>
                  {visibleTableColumns.map((column, id) => {
                    if (column.visible) {
                      return <th key={id}>{column.display_name}</th>;
                    }
                  })}
                </tr>
              </thead>
              <tbody>
                {trip?.trip_locations?.length < 1 && <div>No locations found</div>}
                {trip?.trip_locations
                  ?.filter(
                    (trip_location) =>
                      showNonStation || trip_location?.location?.allow_stops === true
                  )
                  .map((trip_location, index) => {
                    return (
                      <TableRow
                        trip={trip}
                        trip_location={trip_location}
                        key={index}
                        index={index}
                        visibleTableColumns={visibleTableColumns}
                        editMode={editMode}
                        locationsToBeEdited={locationsToBeEdited}
                        setLocationsToBeEdited={setLocationsToBeEdited}
                        operatorTripLocationFields={operatorTripLocationFields}
                        setOriginateModalIsOpen={setOriginateModalIsOpen}
                        setTerminateModalIsOpen={setTerminateModalIsOpen}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="card-footer py-2 px-4 bg-base-300 rounded-b">
            <span className="font-bold">Total Mileage</span>{" "}
            {isLoading && <LoadingSpinner />}
            {trip?.actual_mileage?.toFixed(2)}
          </div>
        </div>
      </div>

      {modifyTripDrawerIsOpen && (
        <ModifyTrip
          modifyTripDrawerIsOpen={modifyTripDrawerIsOpen}
          setModifyTripDrawerIsOpen={setModifyTripDrawerIsOpen}
          trip={trip}
          operator_short_name={operator_short_name}
          service_date={service_date}
          trip_name={trip?.trip_name}
          tripIsLoading={isLoading}
        />
      )}

      {rerouteTripDrawerIsOpen && (
        <RerouteTrip
          rerouteTripDrawerIsOpen={rerouteTripDrawerIsOpen}
          setRerouteTripDrawerIsOpen={setRerouteTripDrawerIsOpen}
          trip={trip}
          operator_short_name={operator_short_name}
          service_date={service_date}
        />
      )}
    </div>
  );
}
