import LoadingSpinner from "../../../icons/LoadingSpinner";
import TextField from "../../TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrain } from "@fortawesome/pro-regular-svg-icons";

const TrainField = ({
  vehicle,
  occurrencePayload,
  setOccurrencePayload,
  handleVehicleLookUp,
  searchingVehicle,
  setVehicleSearchPayload,
  vehicleSearchPayload,
}) => (
  <div className="form-control">
    <label className="label">
      <span className="label-text">
        Conflicting Train{" "}
        {vehicle === "REQUIRED" && <span className="text-error">*</span>}
      </span>
      {occurrencePayload.vehicle && occurrencePayload.vehicle !== "" && (
        <button
          className="btn btn-sm btn-outline btn-error"
          onClick={() => {
            setOccurrencePayload({
              ...occurrencePayload,
              vehicle: "",
            });
            setVehicleSearchPayload({
              ...vehicleSearchPayload,
              carrier_code: "",
              serial_number: "",
            });
          }}
        >
          Unselect Vehicle
        </button>
      )}
    </label>
    {occurrencePayload.vehicle && occurrencePayload.vehicle !== "" ? (
      <div className="card card-compact p-3 border border-base-300 bg-[#F2F5F7]">
        <div className="flex justify-between gap-10">
          <div className="flex gap-5">
            <div>
              <div>
                <FontAwesomeIcon icon={faTrain} />{" "}
                {occurrencePayload.vehicle.carrier_code}{" "}
                {occurrencePayload.vehicle.serial_number}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="flex gap-2">
        <div className="join">
          <TextField
            className="join-item w-full"
            placeholder={"Carrier Code"}
            value={vehicleSearchPayload.carrier_code}
            handleOnChange={(event) =>
              setVehicleSearchPayload({
                ...vehicleSearchPayload,
                carrier_code: event.target.value,
              })
            }
          />
          <TextField
            className="join-item w-full"
            placeholder={"Serial #"}
            value={vehicleSearchPayload.serial_number}
            handleOnChange={(event) =>
              setVehicleSearchPayload({
                ...vehicleSearchPayload,
                serial_number: event.target.value,
              })
            }
          />
        </div>

        <button
          disabled={searchingVehicle}
          className="btn btn-sm btn-primary lg:btn-md"
          onClick={() => handleVehicleLookUp()}
        >
          Search
          {searchingVehicle && <LoadingSpinner />}
        </button>
      </div>
    )}
  </div>
);

export default TrainField;
