import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { useState, useEffect, useRef } from "react";
import SingleSelect from "./SingleSelect";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import { useFetchFunctions } from "../services/trips";
import RouteCard from "./RouteCard";
import LoadingSpinner from "../icons/LoadingSpinner";
import toast from "react-hot-toast";

function RadioButtons({ handleClickRadio, extendTripPayload }) {
  return (
    <div className="form-control">
      <label className="label px-0">
        <span className="label-text">
          Direction <span className="text-error">*</span>
        </span>
      </label>

      <div className="flex items-center">
        <input
          type="radio"
          name="radio-1"
          className="radio radio-primary mr-2"
          onClick={() => handleClickRadio("upstream")}
        />
        <span
          className={`label-text mr-3 ${extendTripPayload.direction === "upstream" ? "font-bold	" : ""}`}
        >
          Upstream of origin
        </span>
        <input
          type="radio"
          name="radio-1"
          className="radio radio-primary mr-2"
          onClick={() => handleClickRadio("downstream")}
        />
        <span
          className={`label-text ${extendTripPayload.direction === "downstream" ? "font-bold	" : ""}`}
        >
          Downstream of destination
        </span>
      </div>
    </div>
  );
}

const defaultExtendTripPayload = {
  direction: null,
  origin: null,
  destination: null,
  route: null,
};

export default function ModifyTrip({
  modifyTripDrawerIsOpen,
  setModifyTripDrawerIsOpen,
  trip,
  operator_short_name,
  service_date,
  trip_name,
  tripIsLoading,
}) {
  const [extendTripIsOpen, setExtendTripIsOpen] = useState(false);
  const [extendTripPayload, setExtendTripPayload] = useState(
    defaultExtendTripPayload
  );
  const [transportationMode, setTransportationMode] = useState(null);
  const [modifyingTrip, setModifyingTrip] = useState(false);
  const extendTripPayloadInitialized = useRef(false);

  const {
    fetchPaths,
    fetchOperators,
    fetchLocationsByTransportationMode,
    extendTrip,
  } = useFetchFunctions();

  const queryClient = useQueryClient();

  const results = useQueries({
    queries: [
      {
        queryKey: ["operators"],
        queryFn: () => fetchOperators(),
        staleTime: 1000 * 10,
      },
      {
        queryKey: ["locations_by_transportation_mode", transportationMode],
        queryFn: () => fetchLocationsByTransportationMode(transportationMode),
        enabled: !!transportationMode && !!extendTripPayload?.direction,
        staleTime: 1000 * 10,
      },
      {
        queryKey: [
          "routes",
          operator_short_name,
          extendTripPayload.origin,
          extendTripPayload.destination,
        ],
        queryFn: () =>
          fetchPaths(
            operator_short_name,
            extendTripPayload?.origin?.value?.code || extendTripPayload?.origin,
            extendTripPayload?.destination?.value?.code ||
              extendTripPayload?.destination
          ),
        enabled:
          !!transportationMode &&
          !!extendTripPayload.origin &&
          !!extendTripPayload.destination,
        staleTime: 1000 * 10,
      },
    ],
  });

  const operators = results[0];
  const locations = results[1];
  const routes = results[2];

  const handleClickRoute = (route) => {
    const routeJson = JSON.parse(JSON.stringify(route));
    if (extendTripPayload.direction === "upstream") {
      const modifiedDetail = routeJson.detail.slice(0, -1);
      routeJson.detail = modifiedDetail;
      setExtendTripPayload({
        ...extendTripPayload,
        route: routeJson,
      });
    } else if (extendTripPayload.direction === "downstream") {
      const modifiedDetail = routeJson.detail.slice(1);
      routeJson.detail = modifiedDetail;
      setExtendTripPayload({
        ...extendTripPayload,
        route: routeJson,
      });
    }
  };

  useEffect(() => {
    if (operators?.data && !operators?.isLoading) {
      const currentOperator =
        Array.isArray(operators?.data) &&
        operators?.data?.find(
          (operator) => operator.short_name === operator_short_name
        );
      setTransportationMode(currentOperator?.transportation_mode?.name);
    }
  }, [operators?.data, operators?.isLoading]);

  useEffect(() => {
    if (!extendTripPayload.origin || !extendTripPayload.destination) {
      setExtendTripPayload({ ...extendTripPayload, route: null });
      extendTripPayloadInitialized.current = false;
    }
  }, [extendTripPayload.origin, extendTripPayload.destination]);

  useEffect(() => {
    if (extendTripPayload.route && !extendTripPayloadInitialized.current) {
      const updatedDetail = extendTripPayload.route.detail.map((location) => ({
        ...location,
        is_stopping: false,
      }));

      const updatedPayload = {
        ...extendTripPayload,
        route: {
          ...extendTripPayload.route,
          detail: updatedDetail,
        },
      };

      setExtendTripPayload(updatedPayload);
      extendTripPayloadInitialized.current = true;
    }
  }, [routes.data, routes.isLoading, extendTripPayload.route]);

  const handleSaveModifiedTrip = async () => {
    setModifyingTrip(true);

    if (extendTripPayload?.route) {
      const extendTripPayloadJson = JSON.parse(JSON.stringify(extendTripPayload));
      const formattedExtendTripLocations = extendTripPayloadJson.route.detail.map(
        (location) => ({
          code: location.code,
          is_stopping: location?.is_stopping ?? false,
        })
      );
      const formattedExtendTripPayload = {
        direction: extendTripPayloadJson.direction,
        locations: formattedExtendTripLocations,
      };

      try {
        await extendTrip(
          operator_short_name,
          service_date,
          trip_name,
          formattedExtendTripPayload
        ).then((res) => {
          if (!res.error) {
            queryClient.setQueryData(
              ["trip", operator_short_name, service_date, trip_name],
              res
            );
            setModifyingTrip(false);
            setModifyTripDrawerIsOpen(false);
            setExtendTripPayload(defaultExtendTripPayload);
            setExtendTripIsOpen(false);
            extendTripPayloadInitialized.current = false;
            toast.success(`Trip ${trip_name} saved.`);
          }
        });
      } catch (error) {
        console.error("Error extending trip:", error);
        setModifyingTrip(false);
        return;
      }
    }
  };

  return (
    <div className="drawer-side z-10">
      <label htmlFor="modify-trip-drawer" className="drawer-overlay"></label>
      <div className="min-h-full w-full md:w-1/2 xl:w-1/3 bg-base-100 p-5 text-base-content prose">
        <h2>Extend Trip</h2>
        <label
          htmlFor="modify-trip-drawer"
          className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          onClick={() => {
            setModifyTripDrawerIsOpen(false);
            setExtendTripIsOpen(false);
            setExtendTripPayload(defaultExtendTripPayload);
            extendTripPayloadInitialized.current = false;
          }}
        >
          ✕
        </label>

        <div className="card">
          <div className="card-body p-1">
            <RadioButtons
              extendTripPayload={extendTripPayload}
              handleClickRadio={(value) => {
                if (value === "upstream") {
                  setExtendTripPayload({
                    ...extendTripPayload,
                    destination: trip.trip_locations[0]?.location?.code,
                    origin: null,
                    direction: value,
                    route: null,
                  });
                  extendTripPayloadInitialized.current = false;
                } else if (value === "downstream") {
                  setExtendTripPayload({
                    ...extendTripPayload,
                    origin:
                      trip?.trip_locations[trip?.trip_locations?.length - 1]
                        ?.location?.code,
                    destination: null,
                    direction: value,
                    route: null,
                  });
                  extendTripPayloadInitialized.current = false;
                }
              }}
            />

            {extendTripPayload?.direction && (
              <form action="">
                <div className="form-control">
                  <label className="label px-0">
                    <span className="label-text">
                      New{" "}
                      {extendTripPayload?.direction === "downstream"
                        ? "Destination"
                        : "Origin"}{" "}
                      <span className="text-error">*</span>
                    </span>
                  </label>
                  <SingleSelect
                    placeholder={`Select ${extendTripPayload?.direction === "downstream" ? "destination" : "origin"}...`}
                    isDisabled={!transportationMode || locations?.isLoading}
                    value={extendTripPayload?.destination}
                    isLoading={locations.isLoading}
                    handleOnChange={(event) => {
                      if (extendTripPayload?.direction === "downstream") {
                        setExtendTripPayload({
                          ...extendTripPayload,
                          destination: event,
                          route: null,
                        });
                        extendTripPayloadInitialized.current = false;
                      } else if (extendTripPayload?.direction === "upstream") {
                        setExtendTripPayload({
                          ...extendTripPayload,
                          origin: event,
                          route: null,
                        });
                        extendTripPayloadInitialized.current = false;
                      }
                    }}
                    options={
                      locations &&
                      Array.isArray(locations?.data) &&
                      locations?.data?.map((location) => {
                        return {
                          value: location,
                          label: location.name,
                        };
                      })
                    }
                  />
                </div>
              </form>
            )}

            {!routes?.isLoading &&
              extendTripPayload?.origin &&
              extendTripPayload?.destination &&
              Array.isArray(routes?.data) && (
                <div className="flex">
                  <div className="font-bold">{routes?.data?.length}&nbsp;</div>
                  <div>
                    route
                    {(routes?.data?.length > 1 || routes?.data?.length) === 0 && "s"}
                  </div>
                </div>
              )}

            <div className="flex gap-5 overflow-x-auto">
              {routes?.isLoading && <LoadingSpinner />}

              {!routes?.isLoading &&
                extendTripPayload?.origin &&
                extendTripPayload?.destination &&
                Array.isArray(routes?.data) &&
                routes?.data?.map((route, index) => {
                  return (
                    <RouteCard
                      route={route}
                      key={index}
                      index={index}
                      handleClickRoute={handleClickRoute}
                      selectedRoute={extendTripPayload?.route?.path_id}
                    />
                  );
                })}
              {routes?.data?.length === 0 && (
                <div className="italic mx-1">
                  No routes available between the two selected locations
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="collapse collapse-arrow">
          <input type="checkbox" disabled />
          <h3 className="collapse-title px-0 m-0">Occurrence Details</h3>
          <div className="collapse-content px-1">
            <form action="" className="flex flex-col gap-4">
              <div className="form-control max-w-20">
                <label className="label">
                  <span className="label-text">
                    Minutes <span className="text-error">*</span>
                  </span>
                </label>
                <input
                  type="number"
                  placeholder="00"
                  className="input input-bordered"
                />
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                <div className="form-control col-span-1">
                  <label className="label">
                    <span className="label-text">
                      Location From <span className="text-error">*</span>
                    </span>
                  </label>
                  <select className="select select-bordered">
                    <option disabled selected>
                      Pick one
                    </option>
                    <option>Star Wars</option>
                    <option>Harry Potter</option>
                    <option>Lord of the Rings</option>
                    <option>Planet of the Apes</option>
                    <option>Star Trek</option>
                  </select>
                </div>
                <div className="form-control col-span-1">
                  <label className="label">
                    <span className="label-text">Location To</span>
                  </label>
                  <select className="select select-bordered">
                    <option disabled selected>
                      Pick one
                    </option>
                    <option>Star Wars</option>
                    <option>Harry Potter</option>
                    <option>Lord of the Rings</option>
                    <option>Planet of the Apes</option>
                    <option>Star Trek</option>
                  </select>
                </div>
              </div>
              <div className="form-control">
                <label className="label gap-4 justify-start cursor-pointer">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-primary checkbox-xs"
                  />
                  <span className="label-text">Primary</span>
                </label>
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Link Residual to Primary</span>
                </label>
                <div className="input input-bordered flex items-center gap-2">
                  <input
                    type="text"
                    className="grow"
                    placeholder="Search Occurrences by Trip – Cause – Delay Length"
                  />
                  <FontAwesomeIcon icon={faSearch} />
                </div>
                <label className="label">
                  <span className="label-text-alt">Ex: 1701 – Broken Gate – 11</span>
                </label>
              </div>

              <div className="divider m-0"></div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">
                    Cause <span className="text-error">*</span>
                  </span>
                </label>
                <select className="select select-bordered">
                  <option disabled selected>
                    Pick one
                  </option>
                  <option>Star Wars</option>
                  <option>Harry Potter</option>
                  <option>Lord of the Rings</option>
                  <option>Planet of the Apes</option>
                  <option>Star Trek</option>
                </select>
              </div>
              <div className="form-control max-w-40">
                <label className="label">
                  <span className="label-text">Vehicle</span>
                </label>
                <select className="select select-bordered">
                  <option disabled selected>
                    Pick one
                  </option>
                  <option>Star Wars</option>
                  <option>Harry Potter</option>
                  <option>Lord of the Rings</option>
                  <option>Planet of the Apes</option>
                  <option>Star Trek</option>
                </select>
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">
                    Responsible Party <span className="text-error">*</span>
                  </span>
                </label>
                <select className="select select-bordered">
                  <option disabled selected>
                    Pick one
                  </option>
                  <option>Star Wars</option>
                  <option>Harry Potter</option>
                  <option>Lord of the Rings</option>
                  <option>Planet of the Apes</option>
                  <option>Star Trek</option>
                </select>
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Unusual Occurrence</span>
                </label>
                <select className="select select-bordered">
                  <option disabled selected>
                    Pick one
                  </option>
                  <option>Star Wars</option>
                  <option>Harry Potter</option>
                  <option>Lord of the Rings</option>
                  <option>Planet of the Apes</option>
                  <option>Star Trek</option>
                </select>
              </div>

              <div className="divider m-0"></div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">
                    Comments <span className="text-error">*</span>
                  </span>
                </label>
                <textarea className="textarea textarea-bordered h-24"></textarea>
                <label className="label">
                  <span></span>
                  <span className="label-text-alt">000/280</span>
                </label>
              </div>
            </form>
          </div>
        </div>

        <div className="flex gap-2">
          <button
            onClick={() => {
              setModifyTripDrawerIsOpen(false);
              setExtendTripIsOpen(false);
              setExtendTripPayload(defaultExtendTripPayload);
            }}
            className="btn btn-primary btn-outline"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => handleSaveModifiedTrip()}
            disabled={
              modifyingTrip ||
              !extendTripPayload.destination ||
              !extendTripPayload.direction ||
              !extendTripPayload.origin ||
              !extendTripPayload.route
            }
          >
            Save
            {modifyingTrip && <LoadingSpinner />}
          </button>
        </div>
      </div>
    </div>
  );
}
