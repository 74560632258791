import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-regular-svg-icons";

export default function UnusualOccurrencesDrawer({
  unusualOccurrencesDrawerIsOpen,
  setUnusualOccurrencesDrawerIsOpen,
}) {
  return (
    <div className="drawer-side z-10">
      <label htmlFor="unusual-occurrences-drawer" className="drawer-overlay"></label>
      <div className="min-h-full w-full md:w-1/2 xl:w-1/3 bg-base-100 p-5 text-base-content prose">
        <h2>Unusual Occurrence</h2>
        <label
          htmlFor="unusual-occurrences-drawer"
          onClick={() => setUnusualOccurrencesDrawerIsOpen(false)}
          className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
        >
          ✕
        </label>

        <div className="card card-compact mb-7 p-3 border border-success bg-[#e6f8ef]">
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <div className="badge badge-md badge-error">2</div>
              linked occurrences
            </div>
            <button className="btn btn-sm btn-ghost text-primary p-2">
              <span className="hidden sm:inline">Show Occurrences</span>
              <FontAwesomeIcon icon={faEye} />
            </button>
          </div>
        </div>

        <div className="flex gap-2">
          <button
            onClick={() => {
              setUnusualOccurrencesDrawerIsOpen(false);
            }}
            className="btn btn-primary btn-outline"
          >
            Cancel
          </button>
          <button className="btn btn-primary">Update Unusual Occurrence</button>
        </div>
      </div>
    </div>
  );
}
