import LoadingSpinner from "../../../icons/LoadingSpinner";
import { WaiverStatus } from "./utils";

export default function DeleteOccurrenceModal({
  trip_occurrence,
  setOccurrenceToBeDeleted,
  handleDeleteOccurrence,
  trip,
  operator_short_name,
  service_date,
  deletingOccurrence,
}) {
  const {
    delay_minutes,
    is_primary,
    operator_trip_occurrence_cause,
    comments,
    waiver_status,
    occurrence,
    id,
  } = trip_occurrence;

  const location = occurrence?.location || "N/A";
  const responsible_party = occurrence?.responsible_party || "N/A";

  return (
    <dialog
      id="delete_occurrence_modal"
      className={`modal modal-middle prose ${occurrence ? "modal-open" : ""}`}
    >
      <div className="modal-box w-full max-w-2xl">
        <h3 className="mt-0">Delete Occurrence</h3>

        <p>Do you want to delete the following occurrence?</p>

        <div className="flex flex-col sm:flex-row gap-2">
          <div className="border rounded-lg p-4">
            <div className="text-[#808080]">Location</div>
            <div className="text-xl font-bold">{location}</div>
          </div>
          <div className="border rounded-lg p-4">
            <div className="text-[#808080]">Min</div>
            <div className="text-xl font-bold">{delay_minutes}</div>
          </div>
          <div className="border rounded-lg p-4">
            <div className="text-[#808080]">Type</div>
            <div className="text-xl font-bold">{responsible_party}</div>
          </div>
          <div className="border rounded-lg p-4">
            <div className="text-[#808080]">Waiver Status</div>
            <div>
              <WaiverStatus waiver_status={waiver_status} />
            </div>
          </div>
        </div>

        <div className="modal-action justify-start">
          <button
            className="btn btn-primary btn-outline"
            onClick={() => setOccurrenceToBeDeleted(null)}
          >
            Cancel
          </button>
          <button
            className="btn btn-error"
            disabled={deletingOccurrence}
            onClick={() =>
              handleDeleteOccurrence(operator_short_name, occurrence.id, id)
            }
          >
            Delete
            {deletingOccurrence && <LoadingSpinner />}
          </button>
        </div>
      </div>
    </dialog>
  );
}
