import { formatInTimeZone } from "date-fns-tz"; // Adjust the import as necessary

interface LinkedTripOccurrenceCardProps {
  linkedTripOccurrence: any;
  isEditMode: boolean;
}

const LinkedTripOccurrenceCard: React.FC<LinkedTripOccurrenceCardProps> = ({
  linkedTripOccurrence,
  isEditMode,
}) => {
  const trip = isEditMode
    ? linkedTripOccurrence?.linked_occurrence?.trip
    : linkedTripOccurrence?.linked_occurrence?.value?.trip;

  const occurredDatetime = isEditMode
    ? linkedTripOccurrence?.linked_occurrence?.occurred_datetime
    : linkedTripOccurrence?.linked_occurrence?.value?.occurred_datetime;

  const title = isEditMode
    ? linkedTripOccurrence?.linked_occurrence?.title
    : linkedTripOccurrence?.linked_occurrence?.value?.title;

  return (
    <div className="card card-compact p-3 border border-base-300 bg-[#F2F5F7]">
      <div className="flex justify-between gap-10">
        <div className="flex gap-5">
          <div>
            <div>
              <div>{trip}</div>
            </div>
          </div>
          <div>
            {occurredDatetime &&
              formatInTimeZone(occurredDatetime, "America/New_York", "MM/dd/yy")}
          </div>
          {/* <div className="flex">
            <div className="font-bold">23</div> <div>mins</div>
          </div> */}
        </div>
      </div>
      {title}
    </div>
  );
};

export default LinkedTripOccurrenceCard;
