import DatePicker from "react-datepicker";
import { addDays, subDays } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

export default function DateRangePicker({ handleChangeDate, startDate, endDate }) {
  const handleNullDate = () => {
    handleChangeDate(
      formatInTimeZone(new Date(), "America/New_York", "P"),
      formatInTimeZone(new Date(), "America/New_York", "P")
    );
  };

  return (
    <div className="flex items-center">
      <DatePicker
        showIcon
        toggleCalendarOnIconClick
        selected={startDate}
        onChange={(date) => {
          if (date === null) {
            handleNullDate();
          } else {
            handleChangeDate(date, addDays(date, 7));
          }
        }}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        className="input input-bordered input-sm lg:input-md w-full"
      />
      <div className="m-2">to</div>
      <DatePicker
        showIcon
        toggleCalendarOnIconClick
        selected={endDate}
        onChange={(date) => {
          if (date === null) {
            handleNullDate();
          } else {
            handleChangeDate(startDate, date);
          }
        }}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        includeDateIntervals={
          [
            {
              start: subDays(startDate, 7),
              end: addDays(startDate, 7),
            },
          ] as const
        }
        className="input input-bordered input-sm lg:input-md w-full"
      />
    </div>
  );
}
