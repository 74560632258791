import SingleSelect from "../../SingleSelect";
import NumberField from "../../NumberField";

const LinearAssetField = ({
  linear_asset,
  occurrencePayload,
  setOccurrencePayload,
  linearAssets,
}) => (
  <div className="form-control">
    <label className="label">
      <span className="label-text">Linear Asset</span>
      {linear_asset === "REQUIRED" && <span className="text-error">*</span>}
    </label>
    <SingleSelect
      placeholder="Pick one"
      value={occurrencePayload.linear_asset}
      handleOnChange={(linear_asset) =>
        setOccurrencePayload({
          ...occurrencePayload,
          linear_asset,
        })
      }
      options={
        Array.isArray(linearAssets.data) &&
        linearAssets?.data?.map((linearAsset) => {
          return {
            label: linearAsset.name,
            value: linearAsset,
          };
        })
      }
    />
    <div className="max-w-20">
      <label className="label">
        <span className="label-text">
          Start <span className="text-error">*</span>
        </span>
      </label>
      <NumberField
        placeholder="00"
        value={occurrencePayload.start}
        handleOnChange={(event) =>
          setOccurrencePayload({
            ...occurrencePayload,
            start: event.target.value,
          })
        }
      />
    </div>
    <div className="max-w-20">
      <label className="label">
        <span className="label-text">
          End <span className="text-error">*</span>
        </span>
      </label>
      <NumberField
        placeholder="00"
        value={occurrencePayload.end}
        handleOnChange={(event) =>
          setOccurrencePayload({
            ...occurrencePayload,
            end: event.target.value,
          })
        }
      />
    </div>
  </div>
);

export default LinearAssetField;
