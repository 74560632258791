import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRedo, faTimes } from "@fortawesome/pro-regular-svg-icons";
import ModalWrapper from "./ModalWrapper";
import { useState } from "react";
import LoadingSpinner from "../icons/LoadingSpinner";
import { useFetchFunctions } from "../services/trips";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";

function CancelTripModal({
  trip,
  setCancelTripModalIsOpen,
  handleCancelTrip,
  cancelingTrip,
}) {
  return (
    <>
      <h3 className="mt-0">Cancel Trip</h3>

      <p>
        Are you sure you want to cancel{" "}
        <span className="font-bold">Trip {trip?.trip_name}</span>?
      </p>

      <div className="modal-action justify-start">
        <button
          className="btn btn-primary btn-outline"
          onClick={() => setCancelTripModalIsOpen(false)}
        >
          Cancel
        </button>
        <button className="btn btn-error" onClick={() => handleCancelTrip()}>
          Cancel Trip
          {cancelingTrip && <LoadingSpinner />}
        </button>
      </div>
    </>
  );
}

export default function StatTripStatus({
  trip,
  occurrenceDrawer,
  setOccurrenceDrawer,
  service_date,
  operator_short_name,
  trip_name,
}) {
  const { cancelTrip } = useFetchFunctions();
  const queryClient = useQueryClient();

  let mainColor;
  let border;
  let bg;

  const [cancelTripModalIsOpen, setCancelTripModalIsOpen] = useState(false);
  const [cancelingTrip, setCancelingTrip] = useState(false);

  const handleCancelTrip = async () => {
    setCancelingTrip(true);

    try {
      await cancelTrip(service_date, operator_short_name, trip.trip_name).then(
        (res) => {
          if (!res.error) {
            setCancelTripModalIsOpen(false);
            queryClient.setQueryData(
              ["trip", operator_short_name, service_date, trip_name],
              res
            );
            toast.success(`Trip ${trip?.trip_name} saved`);
          }
          setCancelingTrip(false);
        }
      );
    } catch (error) {
      setCancelingTrip(false);
    }
  };

  switch (trip?.status) {
    case "LATE": {
      mainColor = "badge-warning";
      border = "border-warning";
      bg = "bg-[#FFFAE6]";
      break;
    }
    case "ON TIME": {
      mainColor = "badge-success";
      border = "border-success";
      bg = "bg-[#E6F8EF]";
      break;
    }
    case "CANCELED": {
      mainColor = "badge-error";
      border = "border-error";
      bg = "bg-[#FCEBEE]";
      break;
    }
    case "NONE": {
      mainColor = "badge-secondary";
      border = "border-secondary border-dashed";
      bg = "";
      break;
    }
    default: {
      mainColor = "badge-info";
      border = "border-info";
      bg = "bg-[#F2ECF7]";
      break;
    }
  }

  return (
    <div
      className={["stats", "w-full", "h-full", "border", `${border}`, `${bg}`].join(
        " "
      )}
    >
      {cancelTripModalIsOpen && (
        <ModalWrapper modalIsOpen={cancelTripModalIsOpen}>
          <CancelTripModal
            trip={trip}
            cancelingTrip={cancelingTrip}
            setCancelTripModalIsOpen={setCancelTripModalIsOpen}
            handleCancelTrip={handleCancelTrip}
          />
        </ModalWrapper>
      )}

      <div className="stat flex sm:block justify-between items-center p-3 lg:p-4 lg:pb-2">
        <div className="flex gap-2 justify-between">
          <div className={`badge badge-sm sm:badge-md lg:badge-lg ${mainColor}`}>
            {trip?.status}
          </div>
          {trip?.trip_occurrences?.length ? (
            <div className="badge badge-sm sm:badge-md lg:badge-lg badge-primary rounded-full min-w-[22px] lg:min-w-[32px]">
              {trip?.trip_occurrences?.length}
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="stat-actions flex mt-0 -mr-2 sm:mt-1 sm:-ml-2">
          {trip?.status === "CANCELED" ? (
            <button className="btn btn-sm btn-ghost text-primary p-2">
              <span className="hidden sm:inline">Uncancel</span>
              <FontAwesomeIcon icon={faRedo} />
            </button>
          ) : (
            <button
              className="btn btn-sm btn-ghost text-error p-2"
              onClick={() => setCancelTripModalIsOpen(true)}
            >
              <span className="hidden sm:inline">Cancel</span>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}

          <button
            className="btn btn-sm btn-ghost text-primary p-2"
            onClick={() => setOccurrenceDrawer(!occurrenceDrawer)}
          >
            <span className="hidden sm:inline">Add Occurrence</span>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
    </div>
  );
}
