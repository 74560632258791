import { useLoaderData } from 'react-router-dom';
// import { fetchTrips } from '../services/trips';

export async function loader() {
  // const trips = await fetchTrips();
  const trips = [];
  return {};
}

export default function Trips() {
  const { trips } = useLoaderData();
  console.log('trips: ', trips);

  const formatTripValues = (trip_values) => {
    return trip_values.map((trip_value) => {
      return {
        trip_field: trip_value.trip_field.name,
        value: trip_value.value,
      };
    });
  };

  return (
    <div className='prose'>
      <h2>Trips</h2>
      <div className='overflow-x-auto'>
        <table className='table'>
          {/* head */}
          <thead>
            <tr>
              <th></th>
              <th>Trip id</th>
              <th>Trip values</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {trips.length ? (
              trips.map((trip, i) => {
                const tripValues = formatTripValues(trip.trip_values);
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{trip.trip_name}</td>
                    <td>
                      <ul>
                        {tripValues.map((tripVal, i) => {
                          return (
                            <li key={i}>
                              {tripVal.trip_field} - {tripVal.value}
                            </li>
                          );
                        })}
                      </ul>
                    </td>
                    <td></td>
                  </tr>
                );
              })
            ) : (
              <tr />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
