import { useEffect, useState } from "react";
import { Outlet, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThLarge,
  faTrain,
  faAlarmClock,
  faFolderGear,
  faClipboardList,
} from "@fortawesome/pro-regular-svg-icons";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { useMsalAuthentication, useIsAuthenticated } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import Navbar from "../components/Navbar";
import Logo from "../assets/logo.png";
import TLogo from "../assets/T_logo.png";
import { useFetchFunctions } from "../services/auth";
import { TripContext, SearchResultsContext } from "../utils/Contexts";

const shiftReportsUrl = import.meta.env.VITE_SHIFT_REPORTS_URL;

export default function Root() {
  // Sign the user in if there's no active account
  const { result, error } = useMsalAuthentication(InteractionType.Redirect, {
    ...loginRequest,
  });

  // True if the user has authenticated with Entra ID
  const isAuthenticated = useIsAuthenticated();

  const { createOrUpdateUser } = useFetchFunctions();

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        // Ensure the user exists in the TRMS system
        const result = await createOrUpdateUser();
        if (result.error) {
          console.log("Error creating or updating user", result.error);
        } else {
          console.log("User created or updated", result);
        }
      }
    })();
  }, [isAuthenticated, createOrUpdateUser]);

  const [currentTripContext, setCurrentTripContext] = useState(null);
  const [currentSearchResultsContext, setCurrentSearchResultsContext] =
    useState(null);

  return (
    <TripContext.Provider value={{ currentTripContext, setCurrentTripContext }}>
      <SearchResultsContext.Provider
        value={{ currentSearchResultsContext, setCurrentSearchResultsContext }}
      >
        <main>
          <div className="main-nav drawer sm:drawer-open items-start">
            <input id="side-nav" type="checkbox" className="drawer-toggle" />
            <div className="drawer-content flex flex-col items-center justify-center">
              {/* Page content here */}
              <div className="w-full">
                <Navbar />
                <Outlet />
              </div>
            </div>
            <div className="drawer-side">
              <label
                htmlFor="side-nav"
                aria-label="close sidebar"
                className="drawer-overlay"
              ></label>
              <ul className="menu menu-lg p-4 xl:w-64 min-h-full bg-base-100 text-base-content border-r border-base-300">
                <div>
                  <h1 className="flex justify-center">
                    <img src={TLogo} className="xl:hidden" alt="TRMS" />
                    <img src={Logo} className="hidden xl:block" alt="TRMS" />
                  </h1>
                </div>
                <div className="divider"></div>
                <li>
                  <NavLink to={`/`}>
                    <FontAwesomeIcon icon={faThLarge} size="lg" fixedWidth />
                    <span className="hidden xl:inline">Home</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/trip-search`}>
                    <FontAwesomeIcon icon={faTrain} size="lg" fixedWidth />
                    <span className="hidden xl:inline">Trips</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/manage-occurrences`}>
                    <FontAwesomeIcon icon={faAlarmClock} size="lg" fixedWidth />
                    <span className="hidden xl:inline">Occurrences</span>
                  </NavLink>
                </li>
                <li className="dropdown dropdown-right">
                  <div tabIndex={0} role="button" className="flex justify-between">
                    <div className="flex gap-3">
                      <FontAwesomeIcon icon={faFolderGear} size="lg" fixedWidth />
                      <span className="hidden xl:inline">Admin</span>
                    </div>
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      className="hidden xl:inline"
                    />
                  </div>
                  <ul
                    tabIndex={0}
                    className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    <li>
                      <a>Item 1</a>
                    </li>
                    <li>
                      <a>Item 2</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href={shiftReportsUrl}>
                    <FontAwesomeIcon icon={faClipboardList} size="lg" fixedWidth />
                    <span className="hidden xl:inline">Shift Reports</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </main>
      </SearchResultsContext.Provider>
    </TripContext.Provider>
  );
}
