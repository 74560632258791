export default function WaiverStatusForm({
  payload,
  setPayload,
  handleFieldChange,
  formType,
}) {
  return (
    <div className="form-control">
      <label className="label">
        <span className="label-text">
          Waiver Status
          <span className="text-error"> *</span>
        </span>
      </label>
      <div className="join join-horizontal">
        <button
          className={`btn btn-primary ${payload?.waiver_status === "requested" ? "btn-fill" : "btn-outline"} join-item w-1/3`}
          onClick={(event) => {
            event.preventDefault();
            if (payload?.waiver_status === "requested") {
              handleFieldChange("waiver_status", "", formType);
            } else {
              handleFieldChange("waiver_status", "requested", formType);
            }
          }}
        >
          Requested
        </button>
        <button
          className={`btn btn-primary ${payload?.waiver_status === "approved" ? "btn-fill" : "btn-outline"} join-item w-1/3`}
          onClick={(event) => {
            event.preventDefault();
            if (payload?.waiver_status === "approved") {
              handleFieldChange("waiver_status", "", formType);
            } else {
              handleFieldChange("waiver_status", "approved", formType);
            }
          }}
        >
          Approved
        </button>
        <button
          className={`btn btn-primary ${payload?.waiver_status === "denied" ? "btn-fill" : "btn-outline"} join-item w-1/3`}
          onClick={(event) => {
            event.preventDefault();
            if (payload?.waiver_status === "denied") {
              handleFieldChange("waiver_status", "", formType);
            } else {
              handleFieldChange("waiver_status", "denied", formType);
            }
          }}
        >
          Denied
        </button>
      </div>
    </div>
  );
}
