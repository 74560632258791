import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

export default function SignInRedirect() {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { instance: msalInstance } = useMsal();

  /*
  useEffect(() => {
    if (isAuthenticated) {
      msalInstance.acquireTokenSilent({
        scopes: ['api://75358bf2-8b71-4ceb-994b-38a1e5ca789e/.default']
      }).then(result => {
        console.log(result?.accessToken);
        navigate('/');
      })
    }
  }, [isAuthenticated])
  */

  return <div>Signing in...</div>;
}
