import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { findFirstAndLastVehicles } from "../utils/helperFunctions";

export default function StatVehicleFormation({
  handleClickToScroll,
  trip,
  setShowVehicleFormationDrawer,
}) {
  const { firstVehicle, lastVehicle } = findFirstAndLastVehicles(
    trip?.assigned_vehicle_formation?.vehicle_formation_vehicles
  );

  return (
    <div className="stats w-full h-full bg-base-100 border border-base-300">
      <div className="stat flex sm:block justify-between items-center p-3 lg:p-4 lg:pb-2">
        {firstVehicle?.vehicle && lastVehicle?.vehicle ? (
          <div className="flex gap-2 items-center">
            <div className="badge badge-sm sm:badge-md lg:badge-lg badge-outline">
              {firstVehicle?.vehicle?.serial_number}
            </div>
            –
            <div className="badge badge-sm sm:badge-md lg:badge-lg badge-outline">
              {lastVehicle?.vehicle?.serial_number}
            </div>
          </div>
        ) : (
          <div className="lg:text-3xl">No Formation</div>
        )}

        <div className="stat-actions flex mt-0 -mr-2 sm:mt-1 sm:-ml-2">
          {firstVehicle?.vehicle ? (
            <button
              className="btn btn-sm btn-ghost text-primary p-2"
              onClick={handleClickToScroll}
            >
              View Consist <FontAwesomeIcon icon={faArrowDown} />
            </button>
          ) : (
            <button
              onClick={() => setShowVehicleFormationDrawer(true)}
              className="btn btn-sm btn-ghost text-primary p-2"
            >
              Add Consist <FontAwesomeIcon icon={faPlus} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
