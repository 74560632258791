import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrain,
  faArrowUpRight,
  faListTree,
} from "@fortawesome/pro-regular-svg-icons";
import LoadingSpinner from "../../../icons/LoadingSpinner";
import { useState, useEffect } from "react";
import useFetchFunctions from "../../../services/trips";
import {
  defaultoccurrencePayload,
  defaultLinkedoccurrencePayload,
} from "./constants";
import { useQueryClient, useQueries } from "@tanstack/react-query";
import { findFirstAndLastVehicles } from "../../../utils/helperFunctions";
import Checkbox from "../../Checkbox";
import NumberField from "../../NumberField";
import SingleSelect from "../../SingleSelect";
import TextArea from "../../TextArea";
import DynamicFormFields from "./DynamicFormFields";
import { STATIC_CAUSES } from "./constants";
import LinkedTripOccurrenceCard from "./LinkedTripOccurrenceCard";
import DynamicOccurrenceCauseFormFields from "./DynamicOccurrenceCauseFormFields";
import WaiverStatusForm from "./WaiverStatusForm";
import toast from "react-hot-toast";
import { FormatGroupLabel } from "./utils";

export default function TripOccurrenceFlyout({
  operator_short_name,
  service_date,
  trip,
  setOccurrenceDrawer,
  occurrenceToBeEditedProp,
  setOccurrenceToBeEditedProp,
}) {
  const { firstVehicle, lastVehicle } = findFirstAndLastVehicles(
    trip?.assigned_vehicle_formation?.vehicle_formation_vehicles
  );
  const [postingOccurrence, setPostingOccurrence] = useState(false);
  const [searchingVehicle, setSearchingVehicle] = useState(false);
  const [linkingOccurrence, setLinkingOccurrence] = useState(false);

  //form states
  const [occurrencePayload, setOccurrencePayload] = useState(
    defaultoccurrencePayload
  );
  const [vehicleSearchPayload, setVehicleSearchPayload] = useState({
    carrier_code: "",
    serial_number: "",
  });
  const [linkedTripOccurrence, setLinkedTripOccurrence] = useState(
    defaultLinkedoccurrencePayload
  );
  const [
    operatorTripOccurrenceFieldsPayload,
    setOperatorTripOccurrenceFieldsPayload,
  ] = useState({});
  const [occurrenceCauseValuesPayload, setOccurrenceCauseValuesPayload] = useState(
    {}
  );

  function reverseObjectStructure(originalObject) {
    const { trip_occurrence, ...rest } = originalObject;

    const reversedObject = {
      ...trip_occurrence,
      occurrence: rest,
    };

    return reversedObject;
  }

  //edit mode form states
  const [occurrenceToBeEdited, setOccurrenceToBeEdited] = useState({});
  const [linkedTripOccurrenceToBeEdited, setLinkedTripOccurrenceToBeEdited] =
    useState({});
  const [originalValues, setOriginalValues] = useState({});

  //other visual states
  const [tripOccurrenceType, setTripOccurrenceType] = useState("new");
  const [isEditMode, setIsEditMode] = useState(false);
  const [occurrenceCauseFields, setOccurrenceCauseFields] = useState([]);

  const isSaveDisabled = () => {
    if (!isEditMode) return false;

    if (tripOccurrenceType === "new") {
      return Object.keys(occurrenceToBeEdited).length === 0;
    }
    return Object.keys(linkedTripOccurrenceToBeEdited).length === 0;
  };

  const {
    fetchOperators,
    fetchOperatorTripOccurrenceFields,
    postOccurrence,
    fetchResponsibleParties,
    fetchOperatorOccurrenceCauses,
    fetchStationaryAssets,
    fetchLinearAssets,
    fetchVehicle,
    fetchOccurrencesOperatorServiceDay,
    linkTripOccurrenceToExistingOccurrence,
    editTripOccurrence,
    editOccurrence,
  } = useFetchFunctions();

  const queryClient = useQueryClient();

  const results = useQueries({
    queries: [
      {
        queryKey: ["operators"],
        queryFn: () => fetchOperators(),
        enabled: occurrencePayload?.responsible_party_id?.value === "operator",
        staleTime: 1000 * 10,
      },
      {
        queryKey: ["operator_trip_occurrence_fields", operator_short_name],
        queryFn: () => fetchOperatorTripOccurrenceFields(operator_short_name),
        staleTime: 1000 * 10,
      },
      {
        queryKey: ["responsible_parties"],
        queryFn: () => fetchResponsibleParties(),
        staleTime: 1000 * 10,
      },
      {
        queryKey: ["operator_occurrence_causes", operator_short_name],
        queryFn: () => fetchOperatorOccurrenceCauses(operator_short_name),
        staleTime: 1000 * 10,
      },
      {
        queryKey: [
          "stationary_assets",
          operator_short_name,
          service_date,
          trip?.trip_name,
        ],
        queryFn: () =>
          fetchStationaryAssets(
            operator_short_name,
            service_date,
            trip?.trip_name,
            occurrencePayload.operator_trip_occurrence_cause_id.value
              .stationary_asset_type
          ),
        staleTime: 1000 * 10,
        enabled:
          !!occurrencePayload?.operator_trip_occurrence_cause_id?.value
            ?.stationary_asset_type,
      },
      {
        queryKey: [
          "occurrences_operator_service_day",
          operator_short_name,
          service_date,
        ],
        queryFn: () =>
          fetchOccurrencesOperatorServiceDay(operator_short_name, service_date),
        staleTime: 1000 * 10,
        enabled: tripOccurrenceType === "linked",
      },
      {
        queryKey: [
          "linear_assets",
          operator_short_name,
          service_date,
          trip?.trip_name,
        ],
        queryFn: () =>
          fetchLinearAssets(
            operator_short_name,
            service_date,
            trip?.trip_name,
            occurrencePayload.operator_trip_occurrence_cause_id.value
              .stationary_asset_type
          ),
        staleTime: 1000 * 10,
        enabled:
          !!occurrencePayload?.operator_trip_occurrence_cause_id?.value
            ?.stationary_asset_type,
      },
    ],
  });

  const operators = results[0];
  const operatorTripOccurrenceFields = results[1];
  const responsibleParties = results[2];
  const operatorOccurrenceCauses = results[3];
  const stationaryAssets = results[4];
  const occurrencesOperatorServiceDay = results[5];
  const linearAssets = results[6];

  useEffect(() => {
    if (
      operatorTripOccurrenceFields.data &&
      !operatorTripOccurrenceFields.isLoading
    ) {
      const operatorOccurrencePayloadFormat = {};
      operatorTripOccurrenceFields.data.forEach((field) => {
        if (field.trip_occurrence_field.type === "INTEGER") {
          operatorOccurrencePayloadFormat[field.trip_occurrence_field.name] = 2;
        } else {
          operatorOccurrencePayloadFormat[field.trip_occurrence_field.name] = "";
        }
      });
      // setOccurrenceCauseValuesPayload(operatorOccurrencePayloadFormat);
      setOperatorTripOccurrenceFieldsPayload(operatorOccurrencePayloadFormat);
    }
  }, [operatorTripOccurrenceFields.data, operatorTripOccurrenceFields.isLoading]);

  const handleFieldChange = (field, value, formType) => {
    const originalValue = originalValues[field];

    if (formType === "linked") {
      setLinkedTripOccurrence({
        ...linkedTripOccurrence,
        [field]: value,
      });

      if (isEditMode) {
        if (value !== originalValue) {
          setLinkedTripOccurrenceToBeEdited({
            ...linkedTripOccurrenceToBeEdited,
            [field]: value,
          });
        } else {
          const updatedEdited = { ...linkedTripOccurrenceToBeEdited };
          delete updatedEdited[field];
          setLinkedTripOccurrenceToBeEdited(updatedEdited);
        }
      }
    } else {
      setOccurrencePayload({
        ...occurrencePayload,
        [field]: value,
      });

      if (isEditMode) {
        if (value !== originalValue) {
          setOccurrenceToBeEdited({
            ...occurrenceToBeEdited,
            [field]: value,
          });
        } else {
          const updatedEdited = { ...occurrenceToBeEdited };
          delete updatedEdited[field];
          setOccurrenceToBeEdited(updatedEdited);
        }
      }
    }
  };

  const handleVehicleLookUp = async () => {
    setSearchingVehicle(true);

    try {
      await fetchVehicle(
        operator_short_name,
        vehicleSearchPayload.carrier_code,
        vehicleSearchPayload.serial_number
      ).then((res) => {
        if (!res.error) {
          setOccurrencePayload({
            ...occurrencePayload,
            vehicle: res,
          });
        }
        setSearchingVehicle(false);
      });
    } catch (error) {
      console.log("Error:", error);
      setSearchingVehicle(false);
    }
  };

  const handlePostOccurrence = async () => {
    const formattedOperatorTripOccurrenceFieldsPayload = [];
    const formattedOccurrenceCauseValuesPayload = [];

    const occurrenceJson = JSON.parse(JSON.stringify(occurrencePayload));
    const occurrenceCauseValuesJson = JSON.parse(
      JSON.stringify(occurrenceCauseValuesPayload)
    );
    const operatorTripOccurrenceFieldsJson = JSON.parse(
      JSON.stringify(operatorTripOccurrenceFieldsPayload)
    );

    for (const fieldName in occurrenceCauseValuesJson) {
      const value = {
        field: fieldName,
        value: occurrenceCauseValuesJson[fieldName],
      };

      formattedOccurrenceCauseValuesPayload.push(value);
    }

    for (const fieldName in operatorTripOccurrenceFieldsJson) {
      const value = {
        field: fieldName,
        value: operatorTripOccurrenceFieldsJson[fieldName],
      };

      formattedOperatorTripOccurrenceFieldsPayload.push(value);
    }

    const formattedPayload = {
      occurred_datetime: "2024-08-29T17:10:00",
      title: "test title",
      primary: occurrenceJson.is_primary,
      field_report: false,
      linear_asset_start: occurrenceJson.linear_asset_start,
      linear_asset_end: occurrenceJson.linear_asset_end,
      occurrence_cause_id:
        occurrenceJson?.operator_trip_occurrence_cause_id?.value?.id,
      location_id: occurrenceJson.location.value,
      responsible_party_id: occurrenceJson?.responsible_party_id?.value,
      linear_asset_id: null,
      stationary_asset_id: occurrenceJson?.stationary_asset?.value,
      vehicle_id: occurrenceJson?.vehicle,
      values: formattedOccurrenceCauseValuesPayload,
      trip_occurrence: {
        delay_minutes: occurrenceJson?.delay_minutes,
        values: formattedOperatorTripOccurrenceFieldsPayload,
        trip_status_attribution: false,
        waiver_status: occurrenceJson?.waiver_status,
        comments: occurrenceJson?.comments,
      },
    };

    const cleanedPayload = Object.fromEntries(
      Object.entries(formattedPayload).filter(
        ([_, value]) => value !== "" && value !== null && value !== undefined
      )
    );

    setPostingOccurrence(true);
    try {
      await postOccurrence(
        operator_short_name,
        service_date,
        trip.trip_name,
        cleanedPayload
      ).then((res) => {
        if (!res.error) {
          setOccurrenceDrawer(false);
          setOccurrencePayload(defaultoccurrencePayload);
          toast.success(`Trip ${trip?.trip_name} saved`);
          queryClient.setQueryData(
            ["trip", operator_short_name, service_date, trip.trip_name],
            (oldData) => {
              return {
                ...oldData,
                trip_occurrences: [res.trip_occurrence, ...trip.trip_occurrences],
              };
            }
          );
          queryClient.setQueryData(
            ["occurrences_operator_service_day", operator_short_name, service_date],
            (oldData) => {
              return [res, ...oldData];
            }
          );
        }
        setPostingOccurrence(false);
      });
    } catch (error) {
      console.log(error);
      setPostingOccurrence(false);
    }
  };

  const handleLinkTripOccurrence = async () => {
    const occurrenceJson = JSON.parse(JSON.stringify(linkedTripOccurrence));
    const occurrenceValuesJson = JSON.parse(
      JSON.stringify(operatorTripOccurrenceFieldsPayload)
    );

    const formattedOperatorTripOccurrenceFieldsPayload = [];

    for (const fieldName in occurrenceValuesJson) {
      const value = { field: fieldName, value: occurrenceValuesJson[fieldName] };

      formattedOperatorTripOccurrenceFieldsPayload.push(value);
    }

    const formattedPayload = {
      delay_minutes: occurrenceJson?.delay_minutes,
      comments: occurrenceJson?.comments,
      waiver_status: occurrenceJson?.waiver_status,
      occurrence_id: occurrenceJson?.linked_occurrence?.value?.id,
      trip_status_attribution: false,
      values: formattedOperatorTripOccurrenceFieldsPayload,
    };

    setLinkingOccurrence(true);
    try {
      await linkTripOccurrenceToExistingOccurrence(
        operator_short_name,
        service_date,
        trip.trip_name,
        formattedPayload
      ).then((res) => {
        if (!res.error) {
          setOccurrenceDrawer(false);
          toast.success(`Trip ${trip?.trip_name} saved`);
          setLinkedTripOccurrence(defaultLinkedoccurrencePayload);
          queryClient.setQueryData(
            ["trip", operator_short_name, service_date, trip.trip_name],
            (oldData) => {
              return {
                ...oldData,
                trip_occurrences: [res, ...trip.trip_occurrences],
              };
            }
          );
        }
        setLinkingOccurrence(false);
      });
    } catch (error) {
      console.log(error);
      setLinkingOccurrence(false);
    }
  };

  useEffect(() => {
    if (occurrenceToBeEditedProp) {
      setIsEditMode(true);
      setOccurrenceDrawer(true);
      const dataToBeEditedJson = JSON.parse(
        JSON.stringify(occurrenceToBeEditedProp)
      );
      setOriginalValues(dataToBeEditedJson);

      if (dataToBeEditedJson?.primary) {
        setTripOccurrenceType("new");
        const {
          delay_minutes,
          is_primary,
          comments,
          waiver_status,
          occurrence,
          trip_status_attribution,
          trip: trip_id,
          trip_occurrence_values,
        } = dataToBeEditedJson;

        const location = occurrence?.location || "N/A";

        const stationary_asset = occurrence?.stationary_asset || "N/A";
        const vehicle = occurrence?.vehicle || "N/A";
        const responsible_party_id = occurrence?.responsible_party || "N/A";
        const occurrence_cause = occurrence?.occurrence_cause || "N/A";
        const operator = occurrence?.operator || "N/A";
        const values = trip_occurrence_values;

        const currentCause = operatorOccurrenceCauses.data.find(
          (cause) => cause.occurrence_cause.name === occurrence_cause
        );

        const currentLocation = trip.trip_locations.find(
          (location_lookup) => location_lookup.location.code === location
        );

        const formattedOccurrence = {
          is_primary: is_primary,
          delay_minutes: delay_minutes,
          linked_occurrence: occurrence,
          trip_status_attribution: trip_status_attribution,
          reported_only: false, // Assuming default value
          waiver_status: waiver_status,
          comments: comments,
          trip_id: trip_id,
          operator_trip_occurrence_cause_id: {
            value: currentCause.occurrence_cause,
            label: currentCause.occurrence_cause.name,
          },
          vehicle: vehicle,
          responsible_party_id: {
            value: responsible_party_id,
            label: responsible_party_id,
          },
          responsible_operator_id: operator, // Assuming default value
          mmbfna: false, // Assuming default value
          conflicting_train: vehicle?.id, // Assuming default value
          stationary_asset: stationary_asset,
          location: {
            value: currentLocation.location.id,
            label: currentLocation.location.name,
          },
          values: values,
        };
        setOccurrencePayload(formattedOccurrence);
      } else {
        setTripOccurrenceType("linked");
        console.log(
          "operatorTripOccurrenceFieldsPayload",
          operatorTripOccurrenceFieldsPayload
        );
        const { occurrence, trip_occurrence_values } = dataToBeEditedJson;

        const formattedOccurrence = {
          ...dataToBeEditedJson,
          linked_occurrence: occurrence,
          values: trip_occurrence_values,
        };
        setLinkedTripOccurrence(formattedOccurrence);
      }
    }
  }, [occurrenceToBeEditedProp]);

  const handleEditTripOccurrence = async () => {
    const occurrenceJson = JSON.parse(JSON.stringify(linkedTripOccurrence));
    const occurrenceValuesJson = JSON.parse(
      JSON.stringify(operatorTripOccurrenceFieldsPayload)
    );
    const editedTripOccurrenceJson = JSON.parse(
      JSON.stringify(linkedTripOccurrenceToBeEdited)
    );

    const formattedOperatorTripOccurrenceFieldsPayload = [];

    for (const fieldName in occurrenceValuesJson) {
      const value = { field: fieldName, value: occurrenceValuesJson[fieldName] };

      formattedOperatorTripOccurrenceFieldsPayload.push(value);
    }

    const formattedPayload = {
      ...editedTripOccurrenceJson,
      values: formattedOperatorTripOccurrenceFieldsPayload,
    };

    setPostingOccurrence(true);
    try {
      await editTripOccurrence(
        operator_short_name,
        linkedTripOccurrence.linked_occurrence.id,
        linkedTripOccurrence.id,
        formattedPayload
      ).then((res) => {
        if (!res.error) {
          setOccurrenceDrawer(false);
          setOccurrenceToBeEditedProp(null);
          toast.success(`Trip ${trip?.trip_name} saved`);
          queryClient.setQueryData(
            ["trip", operator_short_name, service_date, trip.trip_name],
            (oldData) => {
              const updatedTripOccurrences = oldData.trip_occurrences.map(
                (trip_occurrence) => {
                  if (trip_occurrence.id === linkedTripOccurrence.id) {
                    return res;
                  }
                  return trip_occurrence;
                }
              );
              return {
                ...oldData,
                trip_occurrences: updatedTripOccurrences,
              };
            }
          );
        }
        setPostingOccurrence(false);
      });
    } catch (error) {
      console.log(error);
      setPostingOccurrence(false);
    }
  };

  const handleEditOccurrence = async () => {
    const formattedOperatorTripOccurrenceFieldsPayload = [];
    const formattedOccurrenceCauseValuesPayload = [];

    const editedOccurrenceJson = JSON.parse(JSON.stringify(occurrenceToBeEdited));
    const editedLinkedTripOccurrenceJson = JSON.parse(
      JSON.stringify(linkedTripOccurrenceToBeEdited)
    );

    const occurrenceCauseValuesJson = JSON.parse(
      JSON.stringify(occurrenceCauseValuesPayload)
    );
    const operatorTripOccurrenceFieldsJson = JSON.parse(
      JSON.stringify(operatorTripOccurrenceFieldsPayload)
    );

    for (const fieldName in occurrenceCauseValuesJson) {
      const value = {
        field: fieldName,
        value: occurrenceCauseValuesJson[fieldName],
      };

      formattedOccurrenceCauseValuesPayload.push(value);
    }

    for (const fieldName in operatorTripOccurrenceFieldsJson) {
      const value = {
        field: fieldName,
        value: operatorTripOccurrenceFieldsJson[fieldName],
      };

      formattedOperatorTripOccurrenceFieldsPayload.push(value);
    }

    const formattedPayload = {
      ...editedOccurrenceJson,
      values: formattedOccurrenceCauseValuesPayload,
      occurrence_id: originalValues?.occurrence?.id,
      trip_occurrence: {
        ...editedLinkedTripOccurrenceJson,
        trip_occurrence_id: originalValues?.id,
        values: formattedOperatorTripOccurrenceFieldsPayload,
      },
    };

    if (editedOccurrenceJson) {
      Object.keys(editedOccurrenceJson).forEach((key) => {
        formattedPayload.trip_occurrence[key] = editedOccurrenceJson[key];
      });
    }

    setPostingOccurrence(true);
    try {
      await editOccurrence(
        operator_short_name,
        service_date,
        trip?.trip_name,
        formattedPayload
      ).then((res) => {
        if (!res.error) {
          setOccurrenceDrawer(false);
          setOccurrenceToBeEditedProp(null);
          toast.success(`Trip ${trip?.trip_name} saved`);
          queryClient.setQueryData(
            ["trip", operator_short_name, service_date, trip.trip_name],
            (oldData) => {
              const updatedTripOccurrences = oldData.trip_occurrences.map(
                (trip_occurrence) => {
                  if (trip_occurrence.id === res.id) {
                    return res;
                  }
                  return trip_occurrence;
                }
              );
              return {
                ...oldData,
                trip_occurrences: updatedTripOccurrences,
              };
            }
          );
        }
        setPostingOccurrence(false);
      });
    } catch (error) {
      console.log(error);
      setPostingOccurrence(false);
    }
  };

  return (
    <div className="drawer-side z-10">
      <label htmlFor="add-occurrence-drawer" className="drawer-overlay"></label>
      <div className="min-h-full w-full md:w-1/2 xl:w-1/3 bg-base-100 p-5 text-base-content prose">
        <h2>Delay Details</h2>
        <button
          onClick={() => {
            setOccurrenceDrawer(false);
            setOccurrencePayload(defaultoccurrencePayload);
            setVehicleSearchPayload({ carrier_code: "", serial_number: "" });
            setOccurrenceCauseValuesPayload({});
            setOperatorTripOccurrenceFieldsPayload({});
            setLinkedTripOccurrence(defaultLinkedoccurrencePayload);
            setTripOccurrenceType("new");
            setIsEditMode(false);
            setLinkedTripOccurrenceToBeEdited({});
            setOccurrenceToBeEdited({});
            setOccurrenceToBeEditedProp(null);
          }}
          className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
        >
          ✕
        </button>

        <div className="card card-compact mb-7 p-3 border border-info bg-[#F2ECF7]">
          <div className="flex gap-10">
            <div className="flex gap-5">
              <div>
                <div>
                  <FontAwesomeIcon icon={faTrain} /> Trip
                </div>
                <div className="text-[22px] text-primary font-bold">
                  {trip?.trip_name} <FontAwesomeIcon icon={faArrowUpRight} />
                </div>
              </div>
              <div>
                <div className="text-[#808080]">Date</div>
                <div className="text-[22px] font-bold">{service_date}</div>
              </div>
            </div>

            <div>
              <div className="text-[#808080]">Vehicle Formation</div>
              {firstVehicle?.vehicle && lastVehicle?.vehicle ? (
                <div className="flex gap-1 items-center">
                  <div className="badge badge-md badge-outline">
                    {" "}
                    {firstVehicle?.vehicle?.serial_number}
                  </div>
                  -
                  <div className="badge badge-md badge-outline">
                    {" "}
                    {lastVehicle?.vehicle?.serial_number}
                  </div>
                </div>
              ) : (
                <div className="lg:text-3xl">No Formation</div>
              )}
            </div>
          </div>
        </div>

        {!isEditMode && (
          <div className="join join-horizontal w-full mb-6">
            <button
              className={`btn btn-primary join-item w-1/2 ${tripOccurrenceType === "new" ? "btn-success" : "btn-outline"}`}
              onClick={() => setTripOccurrenceType("new")}
            >
              New
            </button>
            <button
              className={`btn btn-primary join-item w-1/2 ${tripOccurrenceType === "linked" ? "btn-success" : "btn-outline"}`}
              onClick={() => setTripOccurrenceType("linked")}
            >
              Link to Existing
            </button>
          </div>
        )}

        {/* new occurrence form */}
        {tripOccurrenceType === "new" && (
          <div>
            <div className="form-control p-2 border border-[#d3d4d7] rounded">
              <label className="label gap-2 justify-start cursor-pointer">
                <Checkbox
                  value={occurrencePayload.is_primary}
                  handleOnChange={(event) =>
                    handleFieldChange("is_primary", event.target.checked)
                  }
                />
                <span className="label-text">This is a primary occurrence</span>
              </label>
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">
                  Occurrence Cause <span className="text-error">*</span>
                </span>
              </label>
              <SingleSelect
                placeholder="Pick one"
                formatGroupLabel={FormatGroupLabel}
                value={occurrencePayload.operator_trip_occurrence_cause_id}
                handleOnChange={(operator_trip_occurrence_cause_id) => {
                  setOccurrenceCauseFields(
                    operator_trip_occurrence_cause_id.value.occurrence_cause_fields
                  );
                  handleFieldChange("operator_trip_occurrence_cause_id", {
                    value: operator_trip_occurrence_cause_id.value.occurrence_cause,
                    label: operator_trip_occurrence_cause_id.label,
                  });
                }}
                options={
                  Array.isArray(operatorOccurrenceCauses?.data) &&
                  Object.values(
                    operatorOccurrenceCauses?.data?.reduce((acc, item) => {
                      const type = item.occurrence_cause.type;
                      if (!acc[type]) {
                        acc[type] = {
                          label: type,
                          options: [],
                        };
                      }
                      acc[type].options.push({
                        value: item,
                        label: item.occurrence_cause.name,
                      });
                      return acc;
                    }, {})
                  )
                }
              />
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">
                  Responsible Party <span className="text-error">*</span>
                </span>
              </label>
              <SingleSelect
                placeholder="Pick one"
                value={occurrencePayload.responsible_party_id}
                handleOnChange={(responsible_party_id) =>
                  handleFieldChange("responsible_party_id", responsible_party_id)
                }
                options={
                  responsibleParties?.data &&
                  Array.isArray(responsibleParties?.data) &&
                  [...STATIC_CAUSES, ...responsibleParties.data]?.map((party) => {
                    return { value: party.id, label: party.name };
                  })
                }
              />
            </div>

            {/* static occurrence cause fields based on cause?  */}
            <DynamicOccurrenceCauseFormFields
              occurrencePayload={occurrencePayload}
              setOccurrencePayload={setOccurrencePayload}
              vehicleSearchPayload={vehicleSearchPayload}
              setVehicleSearchPayload={setVehicleSearchPayload}
              searchingVehicle={searchingVehicle}
              handleVehicleLookUp={handleVehicleLookUp}
              stationaryAssets={stationaryAssets}
              linearAssets={linearAssets}
              trip={trip}
              occurrenceCause={
                occurrencePayload.operator_trip_occurrence_cause_id &&
                occurrencePayload.operator_trip_occurrence_cause_id
              }
            />

            {/* occurrence cause fields linked from occurrence causes */}
            <DynamicFormFields
              fields={occurrenceCauseFields}
              payload={occurrenceCauseValuesPayload}
              setPayload={setOccurrenceCauseValuesPayload}
            />

            <hr className="my-3" />

            <div className="form-control max-w-20">
              <label className="label">
                <span className="label-text">
                  Delay Minutes <span className="text-error">*</span>
                </span>
              </label>
              <NumberField
                placeholder="00"
                value={occurrencePayload.delay_minutes}
                handleOnChange={(event) =>
                  handleFieldChange("delay_minutes", Number(event.target.value))
                }
              />
            </div>

            {/* dynamic operator trip occurrence fields */}
            <DynamicFormFields
              fields={operatorTripOccurrenceFields?.data?.map((field) => {
                return field?.trip_occurrence_field;
              })}
              payload={operatorTripOccurrenceFieldsPayload}
              setPayload={setOperatorTripOccurrenceFieldsPayload}
            />

            {/* WAIVER STATUS */}

            <WaiverStatusForm
              payload={occurrencePayload}
              setPayload={setOccurrencePayload}
              handleFieldChange={handleFieldChange}
            />

            <div className="form-control">
              <label className="label">
                <span className="label-text">
                  Comments
                  <span className="text-error"> *</span>
                </span>
              </label>
              <TextArea
                value={occurrencePayload.comments}
                handleOnChange={(event) =>
                  handleFieldChange("comments", event.target.value)
                }
              />
              <label className="label">
                <span></span>
                <span className="label-text-alt">
                  {occurrencePayload.comments.length}/280
                </span>
              </label>
            </div>
          </div>
        )}
        {/* end of new form */}

        {/* link to existing form */}
        {tripOccurrenceType === "linked" && (
          <div>
            <div>
              <label className="label">
                <span className="label-text">
                  {linkedTripOccurrence.linked_occurrence === "" ||
                  !linkedTripOccurrence.linked_occurrence ? (
                    <span>
                      Link this to<span className="text-error"> *</span>
                    </span>
                  ) : (
                    <div>
                      <FontAwesomeIcon icon={faListTree} size="lg" /> This is linked
                      to
                    </div>
                  )}
                </span>
                {linkedTripOccurrence.linked_occurrence &&
                  linkedTripOccurrence.linked_occurrence !== "" && (
                    <button
                      className="btn btn-sm btn-outline btn-error"
                      onClick={() =>
                        handleFieldChange("linked_occurrence", "", "linked")
                      }
                    >
                      UNLINK
                    </button>
                  )}
              </label>
              {linkedTripOccurrence.linked_occurrence === "" ||
              !linkedTripOccurrence.linked_occurrence ? (
                <SingleSelect
                  placeholder="Select Occurrence"
                  value={linkedTripOccurrence.linked_occurrence}
                  handleOnChange={(linked_occurrence) =>
                    handleFieldChange(
                      "linked_occurrence",
                      linked_occurrence,
                      "linked"
                    )
                  }
                  options={occurrencesOperatorServiceDay?.data?.map((occurrence) => {
                    return {
                      value: occurrence,
                      label: `${occurrence.trip} - ${occurrence.occurrence_cause}`,
                    };
                  })}
                />
              ) : (
                <LinkedTripOccurrenceCard
                  linkedTripOccurrence={linkedTripOccurrence}
                  isEditMode={isEditMode}
                />
              )}
            </div>

            <div className="form-control max-w-20">
              <label className="label">
                <span className="label-text">
                  Delay Minutes <span className="text-error">*</span>
                </span>
              </label>
              <NumberField
                placeholder="00"
                value={linkedTripOccurrence.delay_minutes}
                handleOnChange={(event) =>
                  handleFieldChange(
                    "delay_minutes",
                    Number(event.target.value),
                    "linked"
                  )
                }
              />
            </div>

            {/* WAIVER STATUS */}
            <WaiverStatusForm
              payload={linkedTripOccurrence}
              setPayload={setLinkedTripOccurrence}
              handleFieldChange={handleFieldChange}
              formType={"linked"}
            />

            <div className="form-control">
              <label className="label">
                <span className="label-text">
                  Mechanical Department Comments
                  <span className="text-error">*</span>
                </span>
              </label>
              <TextArea
                value={linkedTripOccurrence.comments}
                handleOnChange={(event) =>
                  handleFieldChange("comments", event.target.value, "linked")
                }
              />
              <label className="label">
                <span></span>
                <span className="label-text-alt">
                  {occurrencePayload.comments.length}/280
                </span>
              </label>
            </div>

            {/* dynamic operator trip occurrence fields */}
            {Array.isArray(operatorTripOccurrenceFields?.data) &&
              operatorTripOccurrenceFields?.data?.map((field, index) => {
                return (
                  <DynamicFormFields
                    key={index}
                    field={field.trip_occurrence_field}
                    payload={operatorTripOccurrenceFieldsPayload}
                    setPayload={setOperatorTripOccurrenceFieldsPayload}
                  />
                );
              })}
          </div>
        )}
        {/* end of link to existing form */}

        <hr className="my-3" />

        <div className="flex gap-2 mt-5">
          <button
            onClick={() => {
              setOccurrenceDrawer(false);
              setOccurrencePayload(defaultoccurrencePayload);
              setVehicleSearchPayload({ carrier_code: "", serial_number: "" });
              setOccurrenceCauseValuesPayload({});
              setOperatorTripOccurrenceFieldsPayload({});
              setLinkedTripOccurrence(defaultLinkedoccurrencePayload);
              setTripOccurrenceType("new");
              setIsEditMode(false);
              setLinkedTripOccurrenceToBeEdited({});
              setOccurrenceToBeEdited({});
              setOccurrenceToBeEditedProp(null);
            }}
            className="btn btn-primary btn-outline"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            disabled={
              (tripOccurrenceType === "new" &&
                (occurrencePayload.comments.length > 280 ||
                  occurrencePayload.comments === "" ||
                  occurrencePayload.operator_trip_occurrence_cause_id === "" ||
                  occurrencePayload.operator_trip_occurrence_cause_id === null ||
                  occurrencePayload.delay_minutes === "" ||
                  occurrencePayload.delay_minutes === "0" ||
                  occurrencePayload.waiver_status === "" ||
                  occurrencePayload.responsible_party_id === "" ||
                  occurrencePayload.responsible_party_id === null ||
                  postingOccurrence)) ||
              (tripOccurrenceType === "linked" &&
                (linkedTripOccurrence.linked_occurrence === "" ||
                  linkedTripOccurrence.linked_occurrence === null ||
                  linkedTripOccurrence.delay_minutes === "" ||
                  linkedTripOccurrence.delay_minutes === "0" ||
                  linkedTripOccurrence.waiver_status === "" ||
                  linkedTripOccurrence.comments.length > 280 ||
                  linkedTripOccurrence.comments === "" ||
                  linkingOccurrence)) ||
              isSaveDisabled()
            }
            onClick={(event) => {
              event.preventDefault();
              if (tripOccurrenceType === "new") {
                if (isEditMode) {
                  handleEditOccurrence();
                } else {
                  handlePostOccurrence();
                }
              } else if (tripOccurrenceType === "linked") {
                if (isEditMode) {
                  handleEditTripOccurrence();
                } else {
                  handleLinkTripOccurrence();
                }
              }
            }}
          >
            Save
            {(postingOccurrence || linkingOccurrence) && <LoadingSpinner />}
          </button>
        </div>
      </div>
    </div>
  );
}
