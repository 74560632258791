export default function RouteReverseVert() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.64645 14.3536C4.84171 14.5488 5.15829 14.5488 5.35355 14.3536L8.53553 11.1716C8.7308 10.9763 8.7308 10.6597 8.53553 10.4645C8.34027 10.2692 8.02369 10.2692 7.82843 10.4645L5 13.2929L2.17157 10.4645C1.97631 10.2692 1.65973 10.2692 1.46447 10.4645C1.2692 10.6597 1.2692 10.9763 1.46447 11.1716L4.64645 14.3536ZM10.5 14C10.5 14.2761 10.7239 14.5 11 14.5C11.2761 14.5 11.5 14.2761 11.5 14H10.5ZM10.5 5V14H11.5V5H10.5ZM5.5 14L5.5 5H4.5L4.5 14H5.5ZM5.5 5C5.5 3.61929 6.61929 2.5 8 2.5V1.5C6.067 1.5 4.5 3.067 4.5 5H5.5ZM11.5 5C11.5 3.067 9.933 1.5 8 1.5V2.5C9.38071 2.5 10.5 3.61929 10.5 5H11.5Z"
        fill="#D3455B"
      />
    </svg>
  );
}
