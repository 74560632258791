import { formatInTimeZone } from "date-fns-tz";

export default function DateTimePicker({ value, handleOnChange, disabled }) {
  return (
    <input
      disabled={disabled}
      type="datetime-local"
      id="date-time-input"
      value={value?.replace(/-\d{2}:\d{2}$/, "") || ""}
      step={"1000"}
      onChange={(event) => {
        const val = event.target.value;
        const outputFormat = "yyyy-MM-dd HH:mm:ssXXX";
        const formattedDatetime = formatInTimeZone(
          val,
          "America/New_York",
          outputFormat
        ).replace(" ", "T");
        handleOnChange(formattedDatetime);
      }}
      className="input input-bordered input-sm lg:input-md w-full"
    />
  );
}
