export default function RerouteDestinationConnectorIn({ isSelected }) {
  return (
    <svg
      width="30"
      height="35"
      viewBox="0 0 30 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22 22L22 15.5L22 0" stroke="#808080" stroke-width="2" />
      <circle
        cx="22"
        cy="22"
        r="8"
        transform="rotate(-180 22 22)"
        fill={isSelected ? "green" : "#00AAC3"}
      />
      <path
        d="M22 22L2 2"
        stroke={isSelected ? "green" : "#00AAC3"}
        stroke-width="4"
      />
    </svg>
  );
}
