export const apiUrl = import.meta.env.VITE_DJANGO_URL;

export const createAuthHelpers = (msalInstance) => {
  const refreshTokens = async () => {
    const trmsAccessToken = await msalInstance
      .acquireTokenSilent({
        scopes: ["api://75358bf2-8b71-4ceb-994b-38a1e5ca789e/.default"],
      })
      .then((res) => {
        return res.accessToken;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });

    const msGraphAccessToken = await msalInstance
      .acquireTokenSilent({
        scopes: ["User.Read"],
      })
      .then((res) => {
        return res.accessToken;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });

    return {
      trmsAccessToken,
      msGraphAccessToken,
    };
  };

  const authHeaders = async (includeMsGraphAccessTokenHeader?: boolean) =>  {
    const accessTokens = await refreshTokens();

    const result: Record<string, string> = {
      "Content-Type": "application/json",
    }

    if (accessTokens?.trmsAccessToken) {
      result["Authorization"] = "Bearer " + accessTokens.trmsAccessToken;
    }

    if (includeMsGraphAccessTokenHeader && accessTokens?.msGraphAccessToken) {
      result["x-ms-graph-access-token"] = accessTokens.msGraphAccessToken;
    }

    return result;
  };

  return {
    authHeaders,
    refreshTokens
  }
}